import React from "react";
import Header from "components/user/Header";
import banner from "assets/img/banner.png";
import Footer from "components/user/Footer";
import BeritaPopuler from "components/user/BeritaPopuler";
import CardPengumuman from "components/user/pengumuman/CardPengumuman";

function Pengumuman() {
  return (
    <>
      <Header />
      <div className="relative top-[35px] mt-[30px] inline-flex h-auto w-full">
        <img className="banner h-auto w-full" src={banner} alt="banner" />
        <div className="max-w-500 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center font-bold text-white">
          <p className="text-xl md:text-4xl">
            Pengumuman <br /> SD Muhammadiyah Karangkajen
          </p>
        </div>
      </div>
      <div className="row mt-10 flex flex-wrap gap-6 p-5">
        <div className="w-full lg:w-[68%]">
          <CardPengumuman />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Pengumuman;
