import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  FaCircleChevronLeft,
  FaCircleChevronRight,
  FaFileExcel,
  FaFilePdf,
} from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import { BiSolidFileTxt, BiSortAlt2 } from "react-icons/bi";
import ReactPaginate from "react-paginate";

function TableAgenda() {
  const navigate = useNavigate();
  const [agenda, setAgenda] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteAgendaId, setDeleteAgendaId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const agendaPerPage = 50;

  useEffect(() => {
    const fetchAgendaData = async () => {
      try {
        const response = await axios.get(`/api_s/agendas`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setAgenda(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchAgendaData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const handleEditClick = (id) => {
    navigate(`/admin/edit-agenda/${id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteAgendaId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/agendas/${deleteAgendaId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Agenda!");
      const response = await axios.get(`/api_s/agendas`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setAgenda(response.data.data);
    } catch (error) {
      toast.error(
        "Error Hapus Agenda" + (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const handleSortByDate = () => {
    const sortedAgenda = [...agenda].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setAgenda(sortedAgenda);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const pageCount = Math.ceil(agenda.length / agendaPerPage);
  const offset = currentPage * agendaPerPage;
  const currentAgenda = agenda.slice(offset, offset + agendaPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Judul</th>
            <th className="px-6 py-3 text-center">File</th>
            <th className="px-6 py-3 text-center">Author</th>
            <th className="flex items-center justify-center px-6 py-3 text-center">
              <button
                onClick={handleSortByDate}
                className="flex items-center justify-center"
              >
                Tanggal
                <BiSortAlt2 className="ml-1" />
              </button>
            </th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm ">
          {currentAgenda.map((item, index) => (
            <tr
              key={item.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="px-6 py-3 text-center">{offset + index + 1}</td>
              <td className="px-6 py-3 text-left">{item.title}</td>
              <td className="flex items-center justify-center px-6 py-3 text-center">
                {item.file_url && (
                  <>
                    {item.file_url.endsWith(".png") ||
                    item.file_url.endsWith(".jpg") ||
                    item.file_url.endsWith(".jpeg") ||
                    item.file_url.endsWith(".svg") ||
                    item.file_url.endsWith(".webp") ? (
                      <img
                        src={item.file_url}
                        alt={item.title}
                        className="h-6 w-6"
                      />
                    ) : item.file_url.endsWith(".pdf") ? (
                      <FaFilePdf className="h-6 w-6 text-blue-500" />
                    ) : item.file_url.endsWith(".doc") ||
                      item.file_url.endsWith(".docx") ? (
                      <IoDocumentText className="h-6 w-auto text-blue-500" />
                    ) : item.file_url.endsWith(".xls") ||
                      item.file_url.endsWith(".xlsx") ? (
                      <FaFileExcel className="h-6 w-6 text-green-500" />
                    ) : item.file_url.endsWith(".txt") ? (
                      <BiSolidFileTxt className="h-6 w-6 text-blue-500" />
                    ) : (
                      <span>{item.file_url}</span>
                    )}
                  </>
                )}
              </td>
              <td className="px-6 py-3 text-left">{item.User.name}</td>
              <td className="px-6 py-3 text-center">{formatDate(item.date)}</td>
              <td className="px-6 py-3 text-center">
                <div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 transform text-red-500 hover:scale-110 hover:text-red-600"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Agenda
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus agenda ini?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        {pageCount > 1 && (
          <ReactPaginate
            containerClassName={"pagination flex space-x-2"}
            pageClassName={"page-item flex items-center justify-center h-8 w-8"}
            activeClassName={"active bg-blue-500 text-white rounded-full"}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            breakLabel="..."
            previousLabel={
              <FaCircleChevronLeft
                className={`h-8 w-8 ${
                  currentPage === 0
                    ? "cursor-not-allowed text-gray-300"
                    : "cursor-pointer text-gray-400"
                }`}
              />
            }
            nextLabel={
              <FaCircleChevronRight
                className={`h-8 w-8 ${
                  currentPage === pageCount - 1
                    ? "cursor-not-allowed text-gray-300"
                    : "cursor-pointer text-gray-400"
                }`}
              />
            }
          />
        )}
      </div>
    </div>
  );
}

export default TableAgenda;
