import React, { useEffect, useState } from "react";
import CardPopup from "./components/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Popup = () => {
  const navigate = useNavigate();
  const [popups, setPopups] = useState([]);
  const [activePopupId, setActivePopupId] = useState(null);

  const handleTambahClick = () => {
    navigate("/admin/add-popup");
  };

  useEffect(() => {
    const fetchPopupsData = async () => {
      try {
        const response = await axios.get(`/api_s/popups`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setPopups(response.data.data);
        const activePopup = response.data.data.find((popup) => popup.status);
        if (activePopup) {
          setActivePopupId(activePopup.id);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPopupsData();
  }, []);

  const handleDelete = (deletedId) => {
    setPopups((prevPopups) =>
      prevPopups.filter((item) => item.id !== deletedId)
    );
    if (activePopupId === deletedId) {
      setActivePopupId(null);
    }
  };

  const handleStatusUpdate = async (id, newStatus, title, img) => {
    if (newStatus && activePopupId !== null && activePopupId !== id) {
      toast.warning("Hanya satu popup yang dapat aktif");
      return false;
    }

    try {
      await axios.put(
        `/api_s/popups/${id}`,
        { status: newStatus, title: title, image_url: img },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      if (newStatus) {
        setActivePopupId(id);
        setPopups((prevPopups) =>
          prevPopups.map((popup) =>
            popup.id === id
              ? { ...popup, status: true }
              : { ...popup, status: false }
          )
        );
      } else {
        setActivePopupId(null);
        setPopups((prevPopups) =>
          prevPopups.map((popup) =>
            popup.id === id ? { ...popup, status: false } : popup
          )
        );
      }
      toast.success("Status popup berhasil diperbarui");
      return true;
    } catch (error) {
      toast.error(
        "Error memperbarui status popup: " +
          (error.response?.data?.message || error.message)
      );
      return false;
    }
  };

  return (
    <div className="relative flex flex-wrap justify-center">
      <button
        onClick={handleTambahClick}
        className="absolute right-4 top-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
      >
        Tambah Popup
      </button>
      <div className="mt-16 flex w-full flex-wrap justify-center">
        {popups.map((item) => (
          <CardPopup
            key={item.id}
            id={item.id}
            img={item.image_url}
            title={item.title}
            link={item.link}
            status={item.status}
            onDelete={handleDelete}
            onUpdateStatus={handleStatusUpdate}
          />
        ))}
      </div>
    </div>
  );
};

export default Popup;
