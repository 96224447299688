import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { FaFilePdf, FaFileExcel } from "react-icons/fa6";
import { BiSolidFileTxt } from "react-icons/bi";
import { IoDocumentText } from "react-icons/io5";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

function AddAgenda() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    date: "",
    file_url: "",
  });
  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleCancel = () => {
    navigate("/admin/agenda");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBodyChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      body: data,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFiles) return;

    const data = new FormData();
    data.append("file", selectedFiles);

    try {
      const response = await axios.post(`/api_s/upload/single`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });

      return response.data.data.Location;
    } catch (error) {
      toast.error(
        "Error Upload File" + (error.response?.data?.message || error.message)
      );
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const fileURLs = await handleFileUpload();

      const postData = {
        ...formData,
        file_url: fileURLs,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/agendas`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data.data));
      toast.success("Berhasil Tambah Agenda!");
      navigate("/admin/agenda");
    } catch (error) {
      toast.error(
        "Error Tambah Agenda: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Deskripsi
        </label>
        <CKEditor
          editor={ClassicEditor}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
          data={formData.body}
          onChange={handleBodyChange}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Tanggal
        </label>
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          File
        </label>
        <input
          type="file"
          name="files"
          onChange={handleFileChange}
          className="w-full rounded-md px-2 py-2 dark:text-white"
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp, .pdf, .txt,
          .doc, .docx, .xls, .xlsx
        </span>
        {selectedFiles && (
          <div className="ml-4 mt-2 flex items-center">
            {selectedFiles.type.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(selectedFiles)}
                alt={selectedFiles.name}
                className="mr-2 h-6 w-6 object-cover"
              />
            ) : (
              <a
                href={URL.createObjectURL(selectedFiles)}
                target="blank"
                className="flex items-center text-blue-500 hover:text-blue-700"
              >
                {selectedFiles.type === "application/pdf" && (
                  <FaFilePdf className="mr-1 h-6 w-6" />
                )}
                {selectedFiles.type === "text/plain" && (
                  <BiSolidFileTxt className="mr-1 h-6 w-6" />
                )}
                {(selectedFiles.type === "application/msword" ||
                  selectedFiles.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                  <IoDocumentText className="mr-1 h-6 w-6" />
                )}
                {(selectedFiles.type === "application/vnd.ms-excel" ||
                  selectedFiles.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") && (
                  <FaFileExcel className="mr-1 h-6 w-6" />
                )}
              </a>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Tambah
        </button>
      </div>
    </form>
  );
}

export default AddAgenda;
