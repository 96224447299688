import React from "react";
import BeritaPopuler from "components/user/BeritaPopuler";
import Footer from "components/user/Footer";
import Header from "components/user/Header";
import Details from "components/user/pengumuman/Details";

function DetailsPengumuman() {
  return (
    <>
      <Header />
      <div className="row mt-[50px] flex flex-wrap gap-6 p-5 md:mt-[75px]">
        <div className="w-full lg:w-[68%]">
          <Details />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default DetailsPengumuman;
