import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import tiktok from "../../../../assets/img/icon-sosmed/tiktok.png";
import whatsapp from "../../../../assets/img/icon-sosmed/wa.png";
import facebook from "../../../../assets/img/icon-sosmed/fb.png";
import youtube from "../../../../assets/img/icon-sosmed/yt.png";
import instagram from "../../../../assets/img/icon-sosmed/ig.png";
import X from "../../../../assets/img/icon-sosmed/x.png";
import telegram from "../../../../assets/img/icon-sosmed/telegram.png";
import linkedin from "../../../../assets/img/icon-sosmed/linkedIn.png";
import line from "../../../../assets/img/icon-sosmed/line.png";
import messenger from "../../../../assets/img/icon-sosmed/messenger.png";

function AddMedsos() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    link: "",
  });
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [medsos, setMedsos] = useState([]);
  const [availableIcons, setAvailableIcons] = useState([]);

  const socialMediaIcons = [
    { name: "Whatsapp", icon: whatsapp },
    { name: "Facebook", icon: facebook },
    { name: "Instagram", icon: instagram },
    { name: "X (Twitter)", icon: X },
    { name: "Youtube", icon: youtube },
    { name: "Tiktok", icon: tiktok },
    { name: "Telegram", icon: telegram },
    { name: "LinkedIn", icon: linkedin },
    { name: "Line", icon: line },
    { name: "Messenger", icon: messenger },
  ];

  useEffect(() => {
    const fetchMedsos = async () => {
      try {
        const response = await axios.get(`/api_s/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setMedsos(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchMedsos();
  }, []);

  useEffect(() => {
    // Filter out icons that are already in the existing social media list
    const filterAvailableIcons = () => {
      const filteredIcons = socialMediaIcons.filter(
        (icon) => !medsos.some((medsos) => medsos.title === icon.name)
      );
      setAvailableIcons(filteredIcons);
    };

    filterAvailableIcons();
  }, [medsos]);

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
    setFormData((prevData) => ({
      ...prevData,
      title: icon.name,
    }));
  };

  const handleCancel = () => {
    navigate("/admin/medsos");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        ...formData,
        icon: selectedIcon.name,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/medsos`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data.data));
      toast.success("Berhasil Tambah Media Sosial!");
      navigate("/admin/medsos");
    } catch (error) {
      toast.error(
        "Error Tambah Media Sosial: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-4">
      {/* List of social media icons */}
      <div className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-5">
        {availableIcons.map((icon) => (
          <div
            key={icon.name}
            className="cursor-pointer rounded-md border p-2 text-center hover:shadow-lg"
            onClick={() => handleIconClick(icon)}
          >
            <img
              src={icon.icon}
              alt={icon.name}
              className="mx-auto h-12 w-12"
            />
            <div className="mt-2 text-sm">{icon.name}</div>
          </div>
        ))}
      </div>

      {/* Display form if an icon is selected */}
      {selectedIcon && (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-md block font-bold text-gray-800 dark:text-white">
              Icon {selectedIcon.name}
            </label>
            <img
              src={selectedIcon.icon}
              alt={selectedIcon.name}
              className="h-12 w-12"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold text-gray-800 dark:text-white">
              Judul
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              required
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold text-gray-800 dark:text-white">
              Link
            </label>
            <input
              type="url"
              name="link"
              value={formData.link}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              placeholder="https://example.com"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              onClick={handleCancel}
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Tambah
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default AddMedsos;
