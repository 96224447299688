import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";

function Pendidik() {
  const [pendidik, setPendidik] = useState([]);

  useEffect(() => {
    const fetchPendidik = async () => {
      try {
        const response = await axios.get("/api_s/resources", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setPendidik(response.data.data); // Save received data to state
      } catch (error) {
        console.log(error.response);
        toast.error(
          "Error Mengambil Data Pendidik: " +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchPendidik();
  }, []); // Added dependency array

  const isImage = (fileName) => {
    if (!fileName) return false;
    const imageExtensions = ["jpeg", "jpg", "png", "webp", "svg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  return (
    <div className="mt-10 flex flex-col items-center">
      <div className="mb-4 text-3xl font-bold text-blue-700">
        Guru dan Karyawan
      </div>
      <div className="mt-4 grid w-full grid-cols-1 gap-6 px-4 sm:grid-cols-2 lg:grid-cols-4">
        {pendidik.length > 0 ? (
          pendidik.map((item) => (
            <div
              key={item.id}
              className="container rounded-lg bg-white shadow-md"
            >
              {isImage(item.image_url) ? (
                <div className="relative flex h-[250px] items-center justify-center overflow-hidden rounded-t-md">
                  <img
                    className="w-full transform object-contain transition-transform duration-300 hover:scale-110"
                    src={item.image_url}
                    alt={item.name}
                  />
                </div>
              ) : (
                <div className="p-4 text-center">
                  <div className="text-xl font-semibold text-gray-800">
                    {item.name}
                  </div>
                  <p className="text-gray-600">No image available</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center">
            <div className="mt-4 text-xl font-semibold text-gray-800">
              Tidak ada pendidik saat ini.
            </div>
          </div>
        )}
      </div>
      {pendidik.length > 0 && (
        <div className="mt-6 flex items-center justify-center">
          <Link
            to="/pendidik"
            className="inline-flex items-center rounded-[10px] bg-blue-600 px-4 py-2 text-white shadow-lg transition duration-300 hover:bg-blue-500"
          >
            Lihat Selengkapnya
            <IoMdArrowForward className="ml-1 mt-1" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default Pendidik;
