import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";

function CardVisiMisi() {
  const [titleVisi, setTitleVisi] = useState("");
  const [titleMisi, setTitleMisi] = useState("");
  const [visi, setVisi] = useState("");
  const [misi, setMisi] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vision = await axios.get(`/api_s/abouts?title=Visi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const mission = await axios.get(`/api_s/abouts?title=Misi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setTitleVisi(vision.data.data[0]?.title || "");
        setTitleMisi(mission.data.data[0]?.title || "");
        setVisi(vision.data.data[0]?.body || "");
        setMisi(mission.data.data[0]?.body || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mb-8">
      <h3 className="headers text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          {titleVisi || "Visi"} {titleMisi || "Misi"}
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="px-6 py-4">
          <div className="mb-4">
            <h4 className="text-xl font-semibold">{titleVisi || "Visi"}</h4>
            <p className="mt-2 text-gray-700">
              {visi ? parse(visi) : "Visi SD Muhammadiyah Karangkajen"}
            </p>
          </div>
          <div>
            <h4 className="text-xl font-semibold">{titleMisi || "Misi"}</h4>
            <div className="mt-2 list-inside list-decimal text-gray-700">
              {misi ? parse(misi) : "Misi SD Muhammadiyah Karangkajen"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardVisiMisi;
