import React, { useEffect, useState } from "react";
import guru1 from "assets/img/visi-misi1.png";
import guru2 from "assets/img/visi-misi2.png";
import axios from "axios";
import { toast } from "react-toastify";
import parse from "html-react-parser";

function Visi() {
  const [visi, setVisi] = useState("");
  const [imageUrlVisi, setImageUrlVisi] = useState("");
  const [misi, setMisi] = useState("");
  const [imageUrlMisi, setImageUrlMisi] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const visionResponse = await axios.get(`/api_s/abouts?title=Visi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const missionResponse = await axios.get(`/api_s/abouts?title=Misi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const vision = visionResponse.data.data[0];
        const mission = missionResponse.data.data[0];

        //data visi
        setVisi(vision?.body || "");
        setImageUrlMisi(mission?.image_url || "");

        //data misi
        setMisi(mission?.body || "");
        setImageUrlVisi(vision?.image_url || "");
        setDataLoaded(true);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setDataLoaded(true);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="text-black relative flex hidden items-center justify-center p-5 text-center text-[38px] font-bold leading-[normal] [font-family:'Nunito_Sans-Bold',Helvetica] md:block">
        Visi dan Misi SD Muhammadiyah Karangkajen
      </div>
      <div className="mx-auto grid grid-cols-1 gap-8 p-4 md:grid-cols-2">
        <div className="flex flex-col items-center justify-center">
          {dataLoaded && (visi || imageUrlVisi) ? (
            <>
              {imageUrlVisi && (
                <img
                  src={imageUrlVisi}
                  alt="img-visi"
                  className="mb-4 h-[400px]"
                />
              )}
              {visi && (
                <div className="text-justify text-lg">{parse(visi)}</div>
              )}
            </>
          ) : (
            <>
              <img src={guru1} alt="Logo" className="mb-4 h-[400px]" />
              <div className="text-justify text-lg">
                Lorem ipsum dolor sit amet consectetur. In vel id mi
                pellentesque gravida proin. Eu placerat orci lacus mattis ut
                volutpat interdum. Vehicula et diam id sapien quis velit et.
                Orci varius et vel urna feugiat turpis. Dictum rhoncus mauris
                duis enim eget eget nullam vulputate. Vel tincidunt mattis sed
                integer tempor et sagittis. Diam venenatis pretium fermentum
                lacinia nibh malesuada. Ac commodo nunc laoreet ornare. Purus
                vulputate nec sed gravida enim et commodo. Fermentum odio
                posuere ut at porttitor pulvinar ullamcorper. Sed dignissim amet
                mauris volutpat sed ullamcorper donec vitae tincidunt.
                Vestibulum aliquam aliquet venenatis felis adipiscing id odio.
                Ut eget amet ullamcorper aliquam volutpat porta. Egestas proin
                gravida porttitor hendrerit ut sed sed.
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          {dataLoaded && (misi || imageUrlMisi) ? (
            <>
              {/* Mobile devices image*/}
              {imageUrlMisi && (
                <img
                  src={imageUrlMisi}
                  alt="img-misi"
                  className="mb-4 h-auto w-full md:mb-0 md:hidden md:w-auto"
                />
              )}
              {misi && (
                <div className="text-justify text-lg">{parse(misi)}</div>
              )}
              {/* Desktop devices image*/}
              {imageUrlMisi && (
                <img
                  src={imageUrlMisi}
                  alt="img-misi"
                  className="mb-4 hidden h-[400px] w-full object-contain md:mb-0 md:block md:w-auto"
                />
              )}
            </>
          ) : (
            <>
              {/* Mobile devices image*/}
              <img
                src={guru2}
                alt="Logo"
                className="mb-4 h-auto w-full md:mb-0 md:hidden md:w-auto"
              />
              <div className="text-justify text-lg">
                Lorem ipsum dolor sit amet consectetur. In vel id mi
                pellentesque gravida proin. Eu placerat orci lacus mattis ut
                volutpat interdum. Vehicula et diam id sapien quis velit et.
                Orci varius et vel urna feugiat turpis. Dictum rhoncus mauris
                duis enim eget eget nullam vulputate. Vel tincidunt mattis sed
                integer tempor et sagittis. Diam venenatis pretium fermentum
                lacinia nibh malesuada. Ac commodo nunc laoreet ornare. Purus
                vulputate nec sed gravida enim et commodo. Fermentum odio
                posuere ut at porttitor pulvinar ullamcorper. Sed dignissim amet
                mauris volutpat sed ullamcorper donec vitae tincidunt.
                Vestibulum aliquam aliquet venenatis felis adipiscing id odio.
                Ut eget amet ullamcorper aliquam volutpat porta. Egestas proin
                gravida porttitor hendrerit ut sed sed.
              </div>
              {/* Desktop devices image*/}
              <img
                src={guru2}
                alt="Gambar"
                className="mb-4 hidden h-[400px] w-full object-contain md:mb-0 md:block md:w-auto"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Visi;
