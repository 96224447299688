import React, { useEffect, useState } from "react";
import TableKategoriFoto from "./components/TableKategoriFoto";
import AddKategoriFoto from "./components/AddKategoriFoto";
import { toast } from "react-toastify";
import axios from "axios";


function KategoriFoto () {
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [categoriesphoto, setCategoriesPhoto] = useState([]);

    useEffect(() => {
        fetchCategoriesPhoto();
    }, []);
    const fetchCategoriesPhoto = async () => {
        try {
          const response = await axios.get(
            `/api_s/galerycategories`,
            {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            }
          );
          setCategoriesPhoto(response.data.data);
        } catch (error) {
          toast.error("Error fetching categories");
        }
      };

      const handleTambahKategori = () => {
        setShowAddPopup(true);
      };

      const handleClosePopup = () => {
        setShowAddPopup(false);
      };

    return(
        <div className="container mx-auto p-4">
            <div className="mb-4 flex items-center justify-end">
                <button onClick={handleTambahKategori} className="mr-2 rounded bg-blue-500 px-4 py-2 text-white">Tambah Kategori Foto</button>
            </div>
            <TableKategoriFoto categoriesphoto={categoriesphoto} fetchCategoriesPhoto={fetchCategoriesPhoto}></TableKategoriFoto>
            {showAddPopup && (
                <AddKategoriFoto onClose={handleClosePopup} fetchCategoriesPhoto={fetchCategoriesPhoto}></AddKategoriFoto>
            )}
        </div>
    );
};
export default KategoriFoto;
