import React, { useEffect, useState } from "react";
import Sambutan from "./Sambutan";
import ListBerita from "./ListBerita";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const BeritaTerbaru = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_s/posts?status=1`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setCards(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="row flex flex-wrap gap-2">
      <div className="w-full lg:w-[68%]">
        {/* Card Kata Sambutan */}
        <Sambutan />

        {/* Card Berita */}
        <ListBerita />
      </div>

      <div className="w-full lg:ml-auto lg:w-[30%]">
        {/* Card Berita Populer */}
        <h3 className="headers text-[20px]">
          <span className="border-b-2 border-blue-500 font-bold font-bold hover:cursor-pointer hover:text-blue-700">
            Berita Terbaru
          </span>
        </h3>
        <div className="mt-4 flex flex-col">
          {cards.slice(0, 3).map((news) => (
            <Link
              key={news.id}
              to={`/berita/${news.slug}`}
              className="mb-4 flex overflow-hidden rounded-lg border shadow-md hover:shadow-lg"
            >
              <img
                src={news.image}
                alt={news.title}
                className="h-auto w-24 object-contain"
              />
              <div className="flex flex-col justify-between p-4">
                <h4 className="mb-2 text-lg font-semibold">
                  {truncateText(news.title, 40)}
                </h4>
                <p className="text-sm text-gray-500">
                  {formatDate(news.date)}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeritaTerbaru;
