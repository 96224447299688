import React from "react";

const ImageSection = ({ isEditing, imageUrl, title, handleFileChange }) => {
  return (
    <div className="mb-4 md:mb-0 md:w-1/3">
      {isEditing ? (
        <>
          <label className="text-md mb-2 block font-bold font-medium text-gray-800 dark:text-white">
            Gambar
          </label>
          <input
            type="file"
            onChange={handleFileChange}
            className="dark:text-white"
          />
          <span className="ml-1 mt-0 block text-xs text-gray-600">
            *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
          </span>
          {imageUrl && (
            <img src={imageUrl} alt={title} className="mt-2 h-auto w-auto" />
          )}
        </>
      ) : (
        <img
          src={imageUrl || "https://via.placeholder.com/150"}
          alt={title}
          className="h-auto w-full rounded-lg object-cover"
        />
      )}
    </div>
  );
};

export default ImageSection;
