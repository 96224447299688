import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import parse from "html-react-parser";
import Switch from "react-switch";

const Text = () => {
  const navigate = useNavigate();
  const [texts, setTexts] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteTextsId, setDeleteTextsId] = useState(null);
  const [activeTextId, setActiveTextId] = useState(null);

  useEffect(() => {
    const fetchRunningTextData = async () => {
      try {
        const response = await axios.get(`/api_s/runningtexts`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setTexts(response.data.data);
        console.log(response.data.data);
        const activeText = response.data.data.find((text) => text.status);
        if (activeText) {
          setActiveTextId(activeText.id);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchRunningTextData();
  }, []);

  const handleEditText = (id) => {
    navigate(`/admin/edit-text/${id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteTextsId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/runningtexts/${deleteTextsId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Text Berjalan!");
      const response = await axios.get(`/api_s/runningtexts`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setTexts(response.data.data);
    } catch (error) {
      toast.error(
        "Error Hapus Text Berjalan" +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const handleSwitchChange = async (id, newStatus) => {
    if (newStatus && activeTextId !== null && activeTextId !== id) {
      toast.warning("Hanya satu teks berjalan yang dapat aktif");
      return;
    }

    const textToUpdate = texts.find((text) => text.id === id);

    try {
      await axios.put(
        `/api_s/runningtexts/${id}`,
        {
          status: newStatus,
          title: textToUpdate.title,
          body: textToUpdate.body,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      if (newStatus) {
        setActiveTextId(id);
        setTexts((prevTexts) =>
          prevTexts.map((text) =>
            text.id === id
              ? { ...text, status: true }
              : { ...text, status: false }
          )
        );
      } else {
        setActiveTextId(null);
        setTexts((prevTexts) =>
          prevTexts.map((text) =>
            text.id === id ? { ...text, status: false } : text
          )
        );
      }
      toast.success("Status teks berjalan berhasil diperbarui");
    } catch (error) {
      toast.error(
        "Error memperbarui status teks: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="relative flex flex-wrap justify-start">
      <div className="container mx-auto">
        <div className="App">
          {texts.map((text) => (
            <div
              key={text.id}
              className="relative my-5 mt-5 flex w-full flex-row items-start overflow-hidden rounded bg-gray-200 p-5 shadow-lg"
            >
              {parse(text.body)}
              <div className="absolute bottom-4 right-4 flex space-x-3">
                <Switch
                  onChange={(newStatus) =>
                    handleSwitchChange(text.id, newStatus)
                  }
                  checked={text.status}
                  height={14}
                  width={28}
                  handleDiameter={10}
                />
                <FaEdit
                  className="cursor-pointer text-blue-600"
                  onClick={() => handleEditText(text.id)}
                />
                <FaTrash
                  className="cursor-pointer text-red-600"
                  onClick={() => handleDeleteClick(text.id)}
                />
              </div>
            </div>
          ))}
          {showDeletePrompt && (
            <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
              <div className="rounded bg-white p-4 shadow-md">
                <h2 className="mb-4 text-lg font-semibold">
                  Konfirmasi Hapus Text Berjalan
                </h2>
                <p className="mb-4">
                  Apakah anda yakin akan menghapus text berjalan ini?
                </p>
                <div className="flex justify-end gap-4">
                  <button
                    onClick={handleCancelDelete}
                    className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
                  >
                    Batal
                  </button>
                  <button
                    onClick={handleConfirmDelete}
                    className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Text;
