import React from "react";
import InputFields from "./InputFields";

const FormSchool = ({ schoolContact, handleChange, update }) => {
  return (
    <div>
      <form>
        <InputFields
          label="Phone"
          name="phone"
          value={schoolContact.phone}
          onChange={handleChange}
          readOnly={!update}
          placeholder="628784362936"
        />
        <InputFields
          label="Email"
          name="email"
          value={schoolContact.email}
          onChange={handleChange}
          readOnly={!update}
          placeholder="smp.muh.bantul@gmail.com"
        />
        <InputFields
          label="Link Maps"
          name="link_maps"
          value={schoolContact.link_maps}
          onChange={handleChange}
          readOnly={!update}
          placeholder="https://maps.app.goo.gl/mffxZ3Sn14mYZu6j9"
        />
        <InputFields
          label="Koordinat Maps"
          name="coordinates"
          value={schoolContact.coordinates}
          onChange={handleChange}
          readOnly={!update}
        />
      </form>
    </div>
  );
};
export default FormSchool;
