import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddVideo = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    url: "",
    type: "",
    category_id: "",
  });
  const [categoriesvideo, setCategoriesVideo] = useState([]);
  useEffect(() => {
    const fetchCategoriesVideo = async () => {
      try {
        const response = await axios.get(
          `/api_s/galerycategories`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        setCategoriesVideo(response.data.data);
      } catch (error) {
        console.log(error.response);
        toast.error("Error Fetching Kategori Galeri");
      }
    };
    fetchCategoriesVideo();
  }, []);

  const handleCancel = () => {
    navigate("/admin/video");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        ...formData,
        type: "video",
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/galeries`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Tambah Video");
      navigate("/admin/video");
    } catch (error) {
      console.log(error.response);
      toast.error(
        "Error Tambah Video: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Link Video
        </label>
        <textarea
          name="url"
          value={formData.url}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Kategori
        </label>
        <select
          name="category_id"
          value={formData.category_id}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Kategori
          </option>
          {categoriesvideo.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
        >
          Tambah
        </button>
      </div>
    </form>
  );
};
export default AddVideo;
