import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function TableLogo() {
  const navigate = useNavigate();
  const [logos, setLogos] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteLogoId, setDeleteLogoId] = useState(null);

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get("/api_s/logo", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setLogos(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchLogoData();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-logo/${id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteLogoId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/logo/${deleteLogoId}`, {
        headers: {
          Authorization: localStorage.get("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Logo");
      const response = await axios.get("/api_s/logo", {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setLogos(response.data.data);
    } catch (error) {
      toast.error(
        "Gagal Hapus Logo" + (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Judul</th>
            <th className="px-6 py-3 text-center">Logo</th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {logos.map((logo, index) => (
            <tr
              key={logo.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="px-6 py-3 text-center">{logo.title}</td>
              <td className="px-6 py-3 text-center">
                <div className="flex items-center justify-center">
                  <img src={logo.url} alt={logo.title} className="h-2- w-20" />
                </div>
              </td>
              <td className="px-6 py-3 text-center">
                <div className="flex items-center justify-center">
                  <button
                    className="mr-2 w-4 text-blue-500"
                    onClick={() => handleEditClick(logo.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 text-red-500"
                    onClick={() => handleDeleteClick(logo.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Logo
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus logo ini?</p>
            <div className="flex justify-end gap-4">
              <button
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
                onClick={handleCancelDelete}
              >
                Batal
              </button>
              <button
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
                onClick={handleConfirmDelete}
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TableLogo;
