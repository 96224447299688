import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "user",
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/api_s/users/register`, formData, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      })
      .then((response) => {
        toast.success("Registrasi Berhasil, Silakan Login", response.data);
        navigate("/login");
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.message);
      });
  };

  return (
    <div className="mx-auto my-8 max-w-md rounded-md bg-blue-200 p-6 shadow-md">
      <h2 className="mb-6 flex justify-center text-2xl font-bold text-blue-700">
        Registrasi
      </h2>
      <form
        onSubmit={handleSubmit}
        className="relative flex flex-col rounded-lg bg-blue-200 px-6 py-2 font-light text-blue-800"
      >
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-blue-700"
          >
            Nama
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Masukkan nama"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border border-blue-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-blue-700"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Masukkan username"
            value={formData.username}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border border-blue-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-blue-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Masukkan email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border border-blue-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-blue-700"
          >
            Password
          </label>
          <div className="relative w-full">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Masukkan password"
              value={formData.password}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border border-blue-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              required
            />
            <span
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              <IconContext.Provider value={{ size: "20px" }}>
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </IconContext.Provider>
            </span>
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 w-full rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none"
        >
          Registrasi
        </button>
      </form>
    </div>
  );
};

export default Register;
