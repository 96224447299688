import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const EditVisiMisi = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api_s/abouts/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setTitle(response.data.data?.title || "");
        setBody(response.data.data?.body || "");
        setImageUrl(response.data.data?.image_url || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        toast.error(
          "Error Upload Gambar: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const data = { title, body, image_url: imageUrl };
      const response = await axios.put(`/api_s/abouts/${id}`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      console.log(response.data.data);
      toast.success(`Berhasil Edit ${title}!`);
      navigate("/admin/visi-misi");
    } catch (error) {
      toast.error(
        `Error Edit ${title}: ` +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="container mx-auto p-5">
      <div className="mb-4 text-3xl font-bold">Edit {title}</div>
      <CKEditor
        editor={ClassicEditor}
        config={{ extraPlugins: [CustomUploadAdapterPlugin] }}
        data={body}
        onChange={(event, editor) => {
          const data = editor.getData();
          setBody(data);
        }}
      />
      <div className="mt-4">
        <label className="text-md block font-bold text-gray-800">Gambar</label>
        {imageUrl && (
          <img src={imageUrl} alt="image_url" className="mb-2 mt-2 h-36 w-36" />
        )}
        <input type="file" onChange={handleFileChange} />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="mt-4 flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white"
          onClick={() => navigate("/admin/visi-misi")}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white"
          onClick={handleSubmit}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default EditVisiMisi;
