import React, { useEffect, useRef } from "react";
import BeritaPopuler from "components/user/BeritaPopuler";
import Header from "components/user/Header";
import Footer from "components/user/Footer";
import Details from "components/user/berita/Details";
import { IoIosArrowDropupCircle } from "react-icons/io";

function DetailsBerita() {
  const arrowRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + scrollY;

      if (windowBottom >= docHeight / 2) {
        arrowRef.current.style.display = "block";
      } else {
        arrowRef.current.style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <div className="row mt-[50px] flex flex-wrap gap-6 p-5 md:mt-[75px]">
        <div className="w-full lg:w-[68%]">
          <Details />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>

      <Footer />

      <div
        ref={arrowRef}
        className="fixed bottom-10 right-10 cursor-pointer"
        onClick={scrollToTop}
      >
        <IoIosArrowDropupCircle className="h-8 w-8 text-blue-500" />
      </div>
    </>
  );
}

export default DetailsBerita;
