import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  MdOutlineAccessTimeFilled,
  MdPerson,
  MdRemoveRedEye,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ShareSosmed from "../berita/Share";
import { IoDocumentText } from "react-icons/io5";
import { BiSolidFileTxt } from "react-icons/bi";
import { FaFileExcel } from "react-icons/fa6";
import { FaFile } from "react-icons/fa";

function Details() {
  const { slug } = useParams();
  const [detail, setDetail] = useState("");

  const fetchDetail = async () => {
    try {
      const response = await axios.get(`/api_s/agendas?slug=${slug}`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setDetail(response.data.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchDetail();
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const renderFile = () => {
    if (!detail.file_url) return null;

    if (detail.file_url.endsWith(".pdf")) {
      return (
        <embed
          src={detail.file_url}
          type="application/pdf"
          className="h-[500px] w-full"
        />
      );
    } else if (
      detail.file_url.endsWith(".doc") ||
      detail.file_url.endsWith(".docx") ||
      detail.file_url.endsWith(".txt") ||
      detail.file_url.endsWith(".xls") ||
      detail.file_url.endsWith(".xlsx")
    ) {
      let icon;
      if (
        detail.file_url.endsWith(".doc") ||
        detail.file_url.endsWith(".docx")
      ) {
        icon = <IoDocumentText className="mr-1 h-6 w-6 text-blue-500" />;
      } else if (detail.file_url.endsWith(".txt")) {
        icon = <BiSolidFileTxt className="mr-1 h-6 w-6 text-blue-500" />;
      } else if (
        detail.file_url.endsWith(".xls") ||
        detail.file_url.endsWith(".xlsx")
      ) {
        icon = <FaFileExcel className="mr-1 h-6 w-6 text-blue-500" />;
      } else {
        icon = <FaFile className="mr-1 h-6 w-6 text-blue-500" />;
      }
      return (
        <div className="flex items-center">
          {icon}
          <a
            href={detail.file_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Download File
          </a>
        </div>
      );
    } else {
      return (
        <img
          className="h-auto w-full rounded-md"
          src={detail.file_url}
          alt={detail.title}
        />
      );
    }
  };

  return (
    <div className="relative flex flex-col">
      <h2 className="text-[32px] font-bold">{detail.title}</h2>
      <div className="my-2 flex items-center">
        <MdOutlineAccessTimeFilled className="mr-1 h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-400">
          {formatDate(detail.createdAt)}
        </span>
        <MdPerson className="ml-2 mr-1 h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-400">{detail.User?.name}</span>
        <MdRemoveRedEye className="ml-2 mr-1 h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-500">{detail.views}</span>
      </div>
      {/* {detail.file_url && (
        <div className="mt-4 flex items-center justify-center">
          {detail.file_url.includes(".pdf") ? (
            <embed
              src={detail.file_url}
              type="application/pdf"
              className="h-[500px] w-full"
            />
          ) : (
            <img
              className="h-auto w-full"
              src={detail.file_url}
              alt={detail.title}
            />
          )}
        </div>
      )} */}
      {/* <div className="mt-4 flex items-center justify-center">
        {renderFile()}
      </div> */}
      <div className="mt-3 text-justify">
        {typeof detail.body === "string" ? parse(detail.body) : detail.body}
      </div>
      <div className="mt-4 flex items-center justify-center">
        {renderFile()}
      </div>
      <ShareSosmed title={detail.title} />
    </div>
  );
}

export default Details;
