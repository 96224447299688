import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaFileExcel, FaFilePdf } from "react-icons/fa6";
import { BiSolidFileTxt } from "react-icons/bi";
import { IoDocumentText } from "react-icons/io5";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

function EditPengumuman() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    file_url: "",
  });

  const handleCancel = () => {
    navigate("/admin/pengumuman");
  };

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await axios.get(`/api_s/announcements/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const announcementData = response.data.data;
        setFormData({
          title: announcementData.title,
          body: announcementData.body,
          file_url: announcementData.file_url,
        });
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchAnnouncement();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadData = new FormData();
        uploadData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, uploadData, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          file_url: response.data.data.Location,
        }));
      } catch (error) {
        toast.error(
          "Error Upload File" + (error.response?.data?.message || error.message)
        );
      }
    }
  };

  const handleBodyChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      body: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`/api_s/announcements/${id}`, formData, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      console.log(response.data.data);
      toast.success("Berhasil Edit Pengumuman!");
      navigate("/admin/pengumuman");
    } catch (error) {
      toast.error(
        "Error Edit Pengumuman: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Deskripsi
        </label>
        <CKEditor
          editor={ClassicEditor}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
          data={formData.body}
          onChange={handleBodyChange}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          File
        </label>
        <input
          type="file"
          name="files"
          onChange={handleFileChange}
          className="w-full rounded-md px-2 py-2 dark:text-white"
          multiple
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp, .pdf, .txt,
          .doc, .docx, .xls, .xlsx
        </span>
        {formData.file_url && (
          <div className="ml-4 mt-2 flex items-center">
            <a
              href={formData.file_url}
              target="blank"
              className="flex items-center text-blue-500 hover:text-blue-700"
            >
              {formData.file_url.endsWith(".png") ||
              formData.file_url.endsWith(".jpg") ||
              formData.file_url.endsWith(".jpeg") ||
              formData.file_url.endsWith(".svg") ||
              formData.file_url.endsWith(".webp") ? (
                <img
                  src={formData.file_url}
                  alt={formData.title}
                  className="h-6 w-6 object-cover"
                />
              ) : formData.file_url.endsWith(".pdf") ? (
                <FaFilePdf className="h-6 w-6 text-blue-500" />
              ) : formData.file_url.endsWith(".doc") ||
                formData.file_url.endsWith(".docx") ? (
                <IoDocumentText className="h-6 w-auto text-blue-500" />
              ) : formData.file_url.endsWith(".xls") ||
                formData.file_url.endsWith(".xlsx") ? (
                <FaFileExcel className="h-6 w-6 text-green-500" />
              ) : formData.file_url.endsWith(".txt") ? (
                <BiSolidFileTxt className="h-6 w-6 text-blue-500" />
              ) : (
                <span>{formData.file_url}</span>
              )}
            </a>
          </div>
        )}
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Edit
        </button>
      </div>
    </form>
  );
}

export default EditPengumuman;
