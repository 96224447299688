import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { IoMdArrowRoundForward } from "react-icons/io";
import defaultFoto from "../../../assets/img/pengajian.png";

function ListBerita() {
  const [cards, setCards] = useState([]);

  const defaultData = [
    {
      title:
        "Pelaksanaan acara pengajian dalam rangka semarak Milad SD Muhammadiyah Karangkajen ke-69",
      description:
        "Pelaksanaan acara pengajian dalam rangka semarak Milad SD Muhammadiyah Karangkajen ke-69 yang diikuti oleh guru, karyawan, orang tua, dan siswa kelas VII,VII,dan IX",
      date: "2024-09-09T10:00:00Z",
      slug: "",
      image: defaultFoto,
    },
  ];

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_s/posts?status=1`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        // Jika data dari API kosong, gunakan data default
        if (response.data.data && response.data.data.length > 0) {
          setCards(response.data.data);
        } else {
          setCards(defaultData);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setCards(defaultData);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(timeString).toLocaleTimeString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div>
      <div className="mb-8 flex items-center justify-between">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          <h5 className="name-section text-[18px] font-bold">Berita</h5>
        </span>
        <div className="flex items-center">
          <a
            href="/berita"
            className="text-gray-400 no-underline hover:cursor-pointer hover:text-blue-600"
          >
            Lihat selengkapnya
          </a>
          {/* <FaAngleRight className="text-gray-400 hover:cursor-pointer hover:text-gray-900" /> */}
        </div>
      </div>
      <div className="flex flex-wrap">
        {cards.slice(0, 3).map((berita, index) => (
          <div key={index} className="mb-2 w-full">
            <div className="card-berita">
              <div className="card mb-4">
                <div className="flex flex-wrap rounded-xl shadow-lg">
                  <div className="w-full p-2 md:w-1/3">
                    <div className="card-body flex items-center justify-center">
                      <img
                        src={berita.image}
                        className="h-auto w-auto transform rounded-md transition-transform duration-300 hover:scale-110"
                        alt={berita.title}
                      />
                    </div>
                  </div>
                  <div className="w-full p-2 md:w-2/3">
                    <div className="card-body">
                      <div className="mb-2 flex items-center space-x-4 text-sm">
                        <div className="flex items-center">
                          <FaRegCalendarAlt className="mr-2 h-4 w-4 text-blue-500" />
                          {formatDate(berita.date)}
                        </div>
                        <div className="flex items-center">
                          <FaRegClock className="mr-2 h-4 w-4 text-blue-500" />
                          {formatTime(berita.date)} WIB
                        </div>
                      </div>

                      <Link to={`/berita/${berita.slug}`}>
                        <h5 className="card-title-berita text-[20px] font-bold hover:cursor-pointer hover:text-blue-700">
                          {truncateText(berita.title, 120)}
                        </h5>
                      </Link>
                      <p className="card-text-berita mt-4 text-justify">
                        {parse(truncateText(berita.description, 400))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className=" flex justify-center">
        <Link
          to="/berita"
          className="flex items-center rounded-[10px] bg-blue-500 px-3 py-2 text-white hover:bg-blue-600"
        >
          Lihat Selengkapnya
          <IoMdArrowRoundForward className="ml-1" />
        </Link>
      </div>
    </div>
  );
}

export default ListBerita;
