import React from "react";
import { useNavigate } from "react-router-dom";
import TablePendidik from "./components/TablePendidik";

function Pendidik() {
  const navigate = useNavigate();
  const handleTambahPendidik = () => {
    navigate("/admin/add-pendidik");
  };

  const handleTambahJabatan = () => {
    navigate("/admin/add-jabatan ");
  };
  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <button
            onClick={handleTambahJabatan}
            className="mr-2 rounded bg-blue-500 px-4 py-2 text-white"
          >
            Tambah Jabatan
          </button>
          <button  onClick={handleTambahPendidik} className="mr-2 rounded bg-blue-500 px-4 py-2 text-white">
            Tambah Pendidik
          </button>
        </div>    
      </div>
      <TablePendidik></TablePendidik>
    </div>
  );
}
export default Pendidik;
