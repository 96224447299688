import React from "react";
import { useNavigate } from "react-router-dom";
import CardRunningText from "./components/Card";

const Text = () => {
  const navigate = useNavigate();

  const handleTambahText = () => {
    navigate("/admin/add-text");
  };

  return (
    <div className="relative flex flex-wrap justify-start">
      <button
        onClick={handleTambahText}
        className="absolute left-4 top-4 my-3 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
      >
        Tambah Text Berjalan
      </button>
      <div className="container mx-auto mt-16">
        <CardRunningText />
      </div>
    </div>
  );
};
export default Text;
