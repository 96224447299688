import React from "react";
import { FaCalendarAlt } from "react-icons/fa";

const GaleriVideo = ({ videoUrl, createdAt, title }) => {
  const renderMedia = () => {
    const youtubeMatch = videoUrl.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = videoUrl.match(
      /drive\.google\.com\/file\/d\/([^\/]+)\//
    );

    let videoUrls = videoUrl;
    if (youtubeMatch) {
      videoUrls = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoUrls = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }

    return (
      <iframe
        className="md:w-[500px] md:h-[280px] h-[200px] w-full"
        src={videoUrls}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };
  return (
    <div className="rounded overflow-hidden m-4 bg-white border border-gray-400 rounded-lg">
      <div className="">{renderMedia()}</div>
      <div className="px-4 py-2">
        <div className="flex items-center mb-2">
          <FaCalendarAlt className="h-4 w-4 text-blue-600 mr-2" />
          <div className="text-blue-800 text-sm font-semibold">
            {createdAt}
          </div>
        </div>

        <div className="font-bold text-xl mb-2 text-blue-700">{title}</div>
      </div>
    </div>
  );
};

export default GaleriVideo;
