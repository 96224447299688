import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const AddText = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [status, setStatus] = useState("");

  const handleCancel = () => {
    navigate("/admin/text");
  };

  const checkActiveText = async () => {
    try {
      const response = await axios.get(`/api_s/runningtexts?status=1`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      return response.data.data.length > 0;
    } catch (error) {
      toast.error("Error checking active texts.");
      return false;
    }
  };

  const handleSave = async () => {
    if (status === "true") {
      const hasActiveText = await checkActiveText();
      if (hasActiveText) {
        toast.error(
          "Sudah ada text berjalan dengan status aktif. Tidak bisa menambah text berjalan baru dengan status aktif."
        );
        return;
      }
    }
    try {
      const response = await axios.post(
        `/api_s/runningtexts`,
        {
          title,
          body,
          status,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data.data);
      toast.success("Berhasil Tambah Text Berjalan!");
      navigate("/admin/text");
    } catch (error) {
      toast.error(
        "Error tambah text berjalan: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <div className="mt-4">
        <CKEditor
          editor={ClassicEditor}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
          data={body}
          onChange={(event, editor) => {
            const data = editor.getData();
            setBody(data);
            setTitle(data);
            setStatus(data);
          }}
        />
        <div className="mb-4 mt-4">
          <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
            Status
          </label>
          <select
            id="status"
            name="status"
            className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Pilih Status</option>
            <option value="true">active</option>
            <option value="false">non active</option>
          </select>
        </div>
        <div className="my-5 flex justify-end gap-4">
          <button
            type="button"
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            onClick={handleCancel}
          >
            Batal
          </button>
          <button
            type="button"
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            onClick={handleSave}
          >
            Tambah
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddText;
