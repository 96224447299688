import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const AddVisiMisi = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  useEffect(() => {
    if (type === "visi") {
      setTitle("Visi");
    } else if (type === "misi") {
      setTitle("Misi");
    }
  }, [type]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        toast.error(
          "Error Upload Gambar: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const data = { title, body, image_url: imageUrl };

      const response = await axios.post(`/api_s/abouts`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      console.log(response.data.data);
      toast.success(`Berhasil Tambah ${title}!`);
      navigate("/admin/visi-misi");
    } catch (error) {
      toast.error(
        `Error Tambah ${title}: ` +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="container mx-auto p-5">
      <div className="mb-4 text-3xl font-bold">Tambah {title}</div>
      <CKEditor
        editor={ClassicEditor}
        config={{ extraPlugins: [CustomUploadAdapterPlugin] }}
        data={body}
        onChange={(event, editor) => {
          const data = editor.getData();
          setBody(data);
        }}
      />
      <div className="mt-4">
        <label className="text-md block font-bold text-gray-800">Gambar</label>
        {imageUrl && (
          <img src={imageUrl} alt="image_url" className="mb-2 mt-2 h-36 w-36" />
        )}
        <input type="file" onChange={handleFileChange} />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="mt-4 flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white"
          onClick={() => navigate("/admin/visi-misi")}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white"
          onClick={handleSubmit}
        >
          Tambah
        </button>
      </div>
    </div>
  );
};

export default AddVisiMisi;
