import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormSchool from "./components/Form";
import ButtonSchool from "./components/Buttons";
import announce from "../../../assets/img/announce.png";

const ProfileSekolah = () => {
  const [schoolProfile, setSchoolProfile] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [update, setUpdate] = useState(false);
  const [originalProfile, setOriginalProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchoolProfile = async () => {
      try {
        const response = await axios.get(`/api_s/schoolprofiles`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const profile = response.data.data[0] || null;
        setSchoolProfile(profile);
        setOriginalProfile(profile);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`/api_s/provinces`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setProvinces(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchSchoolProfile();
    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (schoolProfile?.province_id) {
        try {
          const response = await axios.get(
            `/api_s/cities?province_id=${schoolProfile.province_id}`,
            {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            }
          );
          setCities(response.data.data);
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        }
      }
    };

    fetchCities();
  }, [schoolProfile?.province_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleBodyChange = (event, editor) => {
    const data = editor.getData();
    setSchoolProfile((prevData) => ({
      ...prevData,
      body: data,
    }));
  };

  const handleSubmit = async () => {
    if (update) {
      try {
        const config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `/api_s/schoolprofiles/${schoolProfile.id}`,
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
          data: JSON.stringify(schoolProfile),
        };

        const response = await axios.request(config);
        console.log(response.data.data);
        toast.success("Berhasil Edit Profile Sekolah!");
        const updatedSchoolProfile = await axios.get(`/api_s/schoolprofiles`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setSchoolProfile(updatedSchoolProfile.data.data[0]);
      } catch (error) {
        toast.error(
          "Error Edit Profile Sekolah: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
    setUpdate((prev) => !prev);
  };

  const getProvinceName = (id) => {
    const province = provinces.find((province) => province.id === id);
    return province ? province.name : "";
  };

  const getCityName = (id) => {
    const city = cities.find((city) => city.id === id);
    return city ? city.name : "";
  };

  const handleCancel = () => {
    setSchoolProfile(originalProfile);
    setUpdate(false);
  };

  const handleAddProfile = () => {
    navigate("/admin/add-profile-sekolah");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative">
      {!schoolProfile ? (
        <div className="flex h-screen items-center justify-center">
          <div className="text-center">
            <img
              className="mx-auto mb-2 h-[390px]"
              src={announce}
              alt="announce"
            />
            <div className="mb-2 text-2xl font-bold dark:text-white">
              Profile Sekolah masih kosong silakan isi profile sekolah terlebih
              dahulu
            </div>
            <button
              type="button"
              className=" rounded-md bg-blue-500 px-3 py-2 text-white"
              onClick={handleAddProfile}
            >
              Isi Profile Sekolah
            </button>
          </div>
        </div>
      ) : (
        <>
          {!update && (
            <button
              type="button"
              className="absolute right-6 top-0 m-4 flex rounded-md bg-blue-500 px-3 py-2 text-white"
              onClick={() => setUpdate(true)}
            >
              Edit
            </button>
          )}
          <div className="p-10">
            <FormSchool
              schoolProfile={schoolProfile}
              provinces={provinces}
              cities={cities}
              handleChange={handleChange}
              handleBodyChange={handleBodyChange}
              update={update}
              getProvinceName={getProvinceName}
              getCityName={getCityName}
            />
            <ButtonSchool
              update={update}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileSekolah;
