import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AdminLayout from "layouts/admin";
import Login from "views/Login";
import { ToastContainer, toast } from "react-toastify";
import DetailsBerita from "views/user/berita/DetailsBerita";
import { jwtDecode } from "jwt-decode";
import Berita from "views/user/berita/Berita";
import Home from "views/user/home/Home";
import Video from "views/user/galeri/video/Video";
import VisiMisi from "views/user/profile/visi-misi/VisiMisi";
import ProfileSekolah from "views/user/profile/profile-sekolah/ProfileSekolah";
import Foto from "views/user/galeri/foto/Foto";
import KontakSekolah from "views/user/profile/kontak/KontakSekolah";
import Sambutan from "views/user/profile/sambutan/Sambutan";
import Pengumuman from "views/user/pengumuman/Pengumuman";
import DetailsPengumuman from "views/user/pengumuman/DetailsPengumuman";
import Register from "views/Register";
import NotFound from "components/user/NotFound";
import Agenda from "views/user/agenda/Agenda";
import DetailsAgenda from "views/user/agenda/DetailsAgenda";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("role");
          setIsLoggedIn(false);
          setUserRole(null);
          setShowLoginPopup(true);
          toast.warn("Session telah habis");
        } else {
          setIsLoggedIn(true);
          setUserRole(localStorage.getItem("role"));
          setShowLoginPopup(false);
        }
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("role");
        setIsLoggedIn(false);
        setUserRole(null);
        setShowLoginPopup(true);
        toast.error("Error decoding token");
      }
    } else {
      setIsLoggedIn(false);
      setUserRole(null);
      if (location.pathname.startsWith("/admin")) {
        setShowLoginPopup(true);
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route
          path="admin/*"
          element={
            isLoggedIn &&
            (userRole === "superadmin" || userRole === "admin") ? (
              <AdminLayout />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/berita" element={<Berita />} />
        <Route path="/berita/:slug" element={<DetailsBerita />} />
        <Route path="/visi-misi" element={<VisiMisi />} />
        <Route path="/profile-sekolah" element={<ProfileSekolah />} />
        <Route path="/kontak" element={<KontakSekolah />} />
        <Route path="/video" element={<Video />} />
        <Route path="/foto" element={<Foto />} />
        <Route path="/sambutan" element={<Sambutan />} />
        <Route path="/pengumuman" element={<Pengumuman />} />
        <Route path="/pengumuman/:slug" element={<DetailsPengumuman />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/agenda/:slug" element={<DetailsAgenda />} />
      </Routes>
      <ToastContainer theme="colored" />
      {showLoginPopup && <Login closePopup={() => setShowLoginPopup(false)} />}
    </>
  );
};

export default App;
