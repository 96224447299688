import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditFoto = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    url: "",
    type: "",
    category_id: "",
  });
  const [categoriesphoto, setCategoriesPhoto] = useState([]);

  //kategori foto

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `/api_s/galerycategories`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        setCategoriesPhoto(response.data.data);
      } catch (error) {
        toast.error("Error fetching kategori");
      }
    };

    fetchCategories();
  }, []);

  //get galery by id
  useEffect(() => {
    const fetchGaleriesId = async () => {
      try {
        const response = await axios.get(
          `/api_s/galeries/${id}`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        const galeriesData = response.data.data;
        setFormData({
          title: galeriesData.title,
          url: galeriesData.url,
          type: galeriesData.type,
          category_id: galeriesData.category_id,
        });
      } catch (error) {
        console.log(error.response);
        toast.error("Error fetching post data");
      }
    };
    fetchGaleriesId();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `/api_s/upload/single`,
        {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        },
        formData
      );
      setFormData((prevData) => ({
        ...prevData,
        url: response.data.data.Location,
      }));
    } catch (error) {
      console.log(error.response);
      toast.error("Error Upload Gambar");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      method: "put",
      url: `/api_s/galeries/${id}`,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        db_name: process.env.REACT_APP_DB_NAME,
      },
      data: JSON.stringify({ ...formData }),
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Edit Foto!");
      navigate("/admin/foto");
    } catch (error) {
      console.log(error.response);
      toast.error(
        "Error Edit Galery" + (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Gambar
        </label>
        <img
          src={formData.url}
          alt={formData.title}
          className="h-auto w-auto"
        />
        <input
          type="file"
          name="image"
          onChange={handleFileChange}
          className="w-full rounded-md px-2 py-2 dark:text-white"
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Kategori
        </label>
        <select
          name="category_id"
          value={formData.category_id}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Kategori
          </option>
          {categoriesphoto.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
          onClick={() => navigate("/admin/foto")}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
        >
          Edit
        </button>
      </div>
    </form>
  );
};
export default EditFoto;
