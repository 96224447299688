import React from "react";
import InputFields from "./InputFields";
import CkEditor from "./CkEditor";
import SelectFields from "./SelectFields";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const FormSchool = ({
  schoolProfile,
  provinces,
  cities,
  handleChange,
  handleBodyChange,
  update,
  getProvinceName,
  getCityName,
}) => {
  return (
    <div>
      <form>
        <InputFields
          label="Nama Sekolah"
          name="school_name"
          value={schoolProfile.school_name}
          onChange={handleChange}
          readOnly={!update}
        />
        <CkEditor
          data={schoolProfile.body}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
          onChange={handleBodyChange}
          update={update}
        />
        <InputFields
          label="Alamat"
          name="address"
          value={schoolProfile.address}
          onChange={handleChange}
          readOnly={!update}
        />
        <SelectFields
          label="Provinsi"
          name="province_id"
          value={schoolProfile.province_id}
          onChange={handleChange}
          options={provinces}
          update={update}
          getSelectedName={getProvinceName}
        />
        <SelectFields
          label="Kabupaten"
          name="city_id"
          value={schoolProfile.city_id}
          onChange={handleChange}
          options={cities}
          update={update}
          getSelectedName={getCityName}
        />
      </form>
    </div>
  );
};

export default FormSchool;
