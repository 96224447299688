import React from "react";

function FolderDrive() {
  const data = [
    {
      id: 1,
      name: "Folder 1",
      date: "15 May 2024",
      type: "Folder",
      size: "2,5 MB",
      option: "....",
    },
    {
      id: 2,
      name: "Folder 1",
      date: "15 May 2024",
      type: "Folder",
      size: "2,5 MB",
      option: "....",
    },
    {
      id: 3,
      name: "Folder 1",
      date: "15 May 2024",
      type: "Folder",
      size: "2,5 MB",
      option: "....",
    },
  ];
  return (
    <div className="mx-auto overflow-y-auto px-4">
      <table className="min-w-full">
        <thead>
          <tr className="bg-gradient-to-r from-blue-500 to-blue-300">
            <th className="px-6 py-3 text-center font-medium tracking-wider text-white">
              Nama
            </th>
            <th className="px-6 py-3 text-center font-medium tracking-wider text-white">
              Diubah Tanggal
            </th>
            <th className="px-6 py-3 text-center font-medium tracking-wider text-white">
              Tipe File
            </th>
            <th className="px-6 py-3 text-center font-medium tracking-wider text-white">
              Ukuran File
            </th>
            <th className="px-6 py-3 text-center font-medium tracking-wider text-white">
              Option
            </th>
          </tr>
        </thead>
        <tbody className="bg-blue-100 text-[#387ADF]">
          {data.map((row) => (
            <tr key={row.id}>
              <td className="whitespace-nowrap px-6 py-4">{row.name}</td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {row.date}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {row.type}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {row.size}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {row.option}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FolderDrive;
