import React from "react";
import { useNavigate } from "react-router-dom";
import TableAgenda from "./components/TableAgenda";

function Agenda() {
  const navigate = useNavigate();

  const handleTambahClick = () => {
    navigate("/admin/add-agenda");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-end">
        <div>
          <button
            className="mr-2 rounded bg-blue-500 px-4 py-2 text-white"
            onClick={handleTambahClick}
          >
            Tambah Agenda
          </button>
        </div>
      </div>
      <TableAgenda />
    </div>
  );
}

export default Agenda;
