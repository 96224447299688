import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const AddProfile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    school_name: "",
    body: "",
    address: "",
    province_id: "",
    city_id: "",
  });
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const handleCancel = () => {
    navigate("/admin/profile-sekolah");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBodyChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      body: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        ...formData,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/schoolprofiles`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(response.data.data);
      toast.success("Berhasil Tambah Profil Sekolah!");
      navigate("/admin/profile-sekolah");
    } catch (error) {
      toast.error(
        "Error Tambah Profil Sekolah: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`/api_s/provinces`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setProvinces(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (formData.province_id) {
        try {
          const response = await axios.get(
            `/api_s/cities?province_id=${formData.province_id}`,
            {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            }
          );
          setCities(response.data.data);
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        }
      }
    };

    fetchCities();
  }, [formData.province_id]);

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Nama Sekolah
        </label>
        <input
          type="text"
          name="school_name"
          value={formData.school_name}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Sekilas Profile
        </label>
        <CKEditor
          editor={ClassicEditor}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
          data={formData.body}
          onChange={handleBodyChange}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Alamat
        </label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Provinsi
        </label>
        <select
          name="province_id"
          value={formData.province_id}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Provinsi
          </option>
          {provinces.map((province) => (
            <option key={province.id} value={province.id}>
              {province.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Kabupaten
        </label>
        <select
          name="city_id"
          value={formData.city_id}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Kabupaten
          </option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Tambah
        </button>
      </div>
    </form>
  );
};

export default AddProfile;
