import React, { useEffect, useState } from "react";
import Header from "components/user/Header";
import banner from "../../../assets/img/banner.png";
import ListAgenda from "components/user/agenda/ListAgenda";
import BeritaPopuler from "components/user/BeritaPopuler";
import Footer from "components/user/Footer";
import axios from "axios";
import { toast } from "react-toastify";

function Agenda() {
  const [headerImage, setHeaderImage] = useState(""); // State untuk menyimpan URL Banner

  useEffect(() => {
    const fetchHeader = async () => {
      try {
        const response = await axios.get(`/api_s/header?title="berita"`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Check if response contains the expected structure
        if (response.data && response.data.data) {
          const imageData = response.data.data;

          // Assuming imageData could be an array of objects, extract the URL properly
          if (Array.isArray(imageData)) {
            // Adjust this logic based on your actual data structure
            setHeaderImage(imageData[3]?.image_url || ""); // Set to an empty string if not found
          } else if (typeof imageData === "string") {
            setHeaderImage(imageData);
          } else {
            toast.error("Invalid image data format.");
          }
        } else {
          toast.error("No data found in the response.");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchHeader();
  }, []);
  return (
    <>
      <Header />
      <div className="relative top-[35px] mt-[30px] inline-flex h-auto w-full">
        {headerImage ? ( // Render the image only if headerImage is available
          <img
            className="banner h-auto w-full"
            src={headerImage}
            alt="banner"
          />
        ) : (
          // Optionally, you can display a message or an empty div if no header image is available
          <div className="banner-placeholder flex h-auto w-full items-center justify-center bg-gray-200">
            <p className="text-gray-600">No Header Image Available</p>
          </div>
        )}
        <div className="max-w-500 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center font-bold text-white">
          <p className="text-xl md:text-4xl">
            Agenda <br /> SD Muhammadiyah Karangkajen
          </p>
        </div>
      </div>

      <div className="row mt-10 flex flex-wrap gap-6 p-5">
        <div className="w-full lg:w-[68%]">
          <ListAgenda />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Agenda;
