import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import Fb from "../../../../assets/img/icon-sosmed/fb.png";
import Ig from "../../../../assets/img/icon-sosmed/ig.png";
import x from "../../../../assets/img/icon-sosmed/x.png";
import Whatsapp from "../../../../assets/img/icon-sosmed/wa.png";
import yt from "../../../../assets/img/icon-sosmed/yt.png";
import tiktok from "../../../../assets/img/icon-sosmed/tiktok.png";
import telegram from "../../../../assets/img/icon-sosmed/telegram.png";
import linkedin from "../../../../assets/img/icon-sosmed/linkedIn.png";
import line from "../../../../assets/img/icon-sosmed/line.png";
import messenger from "../../../../assets/img/icon-sosmed/messenger.png";

function CardProfileSekolah() {
  const [schoolProfile, setSchoolProfile] = useState({
    school_name: "",
    body: "",
    address: "",
    province_id: "",
    city_id: "",
  });
  const [medsos, setMedsos] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchSchoolProfile = async () => {
      try {
        const response = await axios.get(`/api_s/schoolprofiles`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const profile = response.data.data[0];
        setSchoolProfile(profile);
      } catch (error) {
        console.log(error.response);
      }
    };

    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`/api_s/provinces`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setProvinces(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchSchoolProfile();
    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (schoolProfile.province_id) {
        try {
          const response = await axios.get(
            `/api_s/cities?province_id=${schoolProfile.province_id}`,
            {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            }
          );
          setCities(response.data.data);
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        }
      }
    };

    fetchCities();
  }, [schoolProfile.province_id]);

  useEffect(() => {
    const fetchMedsos = async () => {
      try {
        const response = await axios.get(`/api_s/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setMedsos(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchMedsos();
  }, []);

  const getProvinceName = (id) => {
    const province = provinces.find((province) => province.id === id);
    return province ? province.name : "Daerah Istimewa Yogyakarta";
  };

  const getCityName = (id) => {
    const city = cities.find((city) => city.id === id);
    return city ? city.name : "Bantul";
  };

  const socialMediaIcons = [
    { name: "Whatsapp", icon: Whatsapp },
    { name: "Facebook", icon: Fb },
    { name: "Instagram", icon: Ig },
    { name: "X (Twitter)", icon: x },
    { name: "Youtube", icon: yt },
    { name: "Tiktok", icon: tiktok },
    { name: "Telegram", icon: telegram },
    { name: "Linkedin", icon: linkedin },
    { name: "Line", icon: line },
    { name: "Messenger", icon: messenger },
  ];

  return (
    <div className="mb-8">
      <h3 className="headers text-center text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Profile {schoolProfile.school_name || "SD Muhammadiyah Karangkajen"}
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="px-6 py-4">
          <div className="mb-4 flex">
            <p className="w-40 text-xl font-semibold">Nama Sekolah</p>
            <span className="mx-2 text-xl font-semibold">:</span>
            <p className="text-xl font-semibold text-blue-700">
              {schoolProfile.school_name || "SD Muhammadiyah Karangkajen"}
            </p>
          </div>
          <div className="mb-4 flex">
            <p className="w-40 text-xl font-semibold">Sekilas Profile</p>
            <span className="mx-2 text-xl font-semibold">:</span>
            <p className="text-xl font-semibold text-blue-700">
              {schoolProfile.body
                ? parse(schoolProfile.body)
                : "Profil SD Muhammadiyah Karangkajen"}
            </p>
          </div>
          <div className="mb-4 flex">
            <p className="w-40 text-xl font-semibold">Alamat</p>
            <span className="mx-2 text-xl font-semibold">:</span>
            <p className="text-xl font-semibold text-blue-700">
              {schoolProfile.address || "Jl. Gajah Mada No.7B, Kurahan, Bantul"}
            </p>
          </div>
          <div className="mb-4 flex">
            <p className="w-40 text-xl font-semibold">Kabupaten</p>
            <span className="mx-2 text-xl font-semibold">:</span>
            <p className="text-xl font-semibold text-blue-700">
              {getCityName(schoolProfile.city_id)}
            </p>
          </div>
          <div className="mb-4 flex">
            <p className="w-40 text-xl font-semibold">Provinsi</p>
            <span className="mx-2 text-xl font-semibold">:</span>
            <p className="text-xl font-semibold text-blue-700">
              {getProvinceName(schoolProfile.province_id)}
            </p>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <h3 className="headers text-center text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Sosial Media {schoolProfile.school_name || "SD Muhammadiyah Karangkajen"}
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="w-full px-6 py-4">
          <div className="flex justify-center">
            <div className="flex items-center space-x-4">
              {socialMediaIcons
                .filter((icon) => medsos.some((i) => i.title === icon.name))
                .map((icon) => {
                  const socialMediaLink = medsos.find(
                    (i) => i.title === icon.name
                  )?.link;
                  return (
                    <a
                      key={icon.name}
                      href={socialMediaLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="mr-2 h-12 w-12"
                        src={icon.icon}
                        alt={icon.name}
                      />
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardProfileSekolah;
