import React, { useState } from "react";
import { MdArrowDropDown, MdCollections, MdInfo } from "react-icons/md";
import { konten } from "routes.js";
import Links from "./Links";
import {  FaSchool } from "react-icons/fa";
import { school } from "routes.js";
import { media } from "routes.js";
import { pengaturan } from "routes.js";
import { info } from "routes.js";

function Menu() {
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(false);
  const [showMediaDropdown, setShowMediaDropdown] = useState(false);
  const [showInfoDropdown, setShowInfoDropdown] = useState(false);

  const handleSchoolClick = () => {
    setShowSchoolDropdown(!showSchoolDropdown);
  };

  const handleMediaClick = () => {
    setShowMediaDropdown(!showMediaDropdown);
  };

  const handleInfoClick = () => {
    setShowInfoDropdown(!showInfoDropdown);
  };

  return (
    <>
      <span className="flex items-center py-3 pl-8 text-base font-medium text-gray-500">
        Manajemen Konten
      </span>
      <Links routes={konten} />
      <li
        className="flex cursor-pointer items-center py-3 pl-8 text-base font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-white/10"
        onClick={handleInfoClick}
      >
        <MdInfo className="mr-4 h-6 w-6" />
        Informasi
        <MdArrowDropDown className="ml-auto h-6 w-6" />
      </li>
      {showInfoDropdown && (
        <div className="pl-10">
          <Links routes={info} />
        </div>
      )}
      <li
        className="flex cursor-pointer items-center py-3 pl-8 text-base font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-white/10"
        onClick={handleSchoolClick}
      >
        <FaSchool className="mr-4 h-6 w-6" />
        Tentang Sekolah
        <MdArrowDropDown className="ml-auto h-6 w-6" />
      </li>
      {showSchoolDropdown && (
        <div className="pl-10">
          <Links routes={school} />
        </div>
      )}
      <li
        className="flex cursor-pointer items-center py-3 pl-8 text-base font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-white/10"
        onClick={handleMediaClick}
      >
        <MdCollections className="mr-4 h-6 w-6" />
        Media
        <MdArrowDropDown className="ml-auto h-6 w-6" />
      </li>
      {showMediaDropdown && (
        <div className="pl-10">
          <Links routes={media} />
        </div>
      )}
      <span className="flex items-center py-3 pl-8 text-base font-medium text-gray-500">
        Pengaturan
      </span>
      <Links routes={pengaturan} />
      <span className="flex items-center py-3 pl-8 text-base font-medium text-gray-500">
        Tampilan
      </span>
    </>
  );
}

export default Menu;
