import React from "react";
// import siswa from "assets/img/siswa.png";
// import { MdSearch } from "react-icons/md";
// import Sejarah from "./Sejarah";
import Visi from "./Visi";
import BeritaTerbaru from "./BeritaTerbaru";

function Welcome() {
  return (
    <>
      <div className="relative top-[35px] inline-flex flex-row items-start gap-[35px] p-5">
        <div className="flex flex-col gap-[35px]">
          {/* <div className="text-black relative mt-[-1.04px] w-fit text-[38px] font-bold leading-[normal] [font-family:'Nunito_Sans-Bold',Helvetica]">
            Selamat Datang di
          </div>
          <div className="text-black relative mt-[-20px] text-[38px] font-bold leading-[normal] [font-family:'Nunito_Sans-Bold',Helvetica]">
            SDN Tegalrejo I
          </div>
          <div className="relative inline-flex items-center gap-[401px] rounded-[29px] bg-[#2f2f2f26] md:w-[520px]">
            <input
              type="text"
              placeholder="Cari . . ."
              className="relative inline-flex w-full rounded-[29px] border-none bg-[#2f2f2f26] px-4 py-2 text-[16px]  leading-[normal] tracking-[0] placeholder-[#797979] outline-none"
            />
            <MdSearch className="absolute right-[15px] h-6 w-6" />
          </div>

          <p className="text-black relative text-justify text-[16px] font-normal leading-[normal] tracking-[0] [font-family:'Nunito_Sans-Regular',Helvetica]">
            Lorem ipsum dolor sit amet consectetur. Sit elit tempus dolor eros.
            Convallis purus lectus pellentesque a metus senectus lectus posuere
            ut. Elit ultricies dignissim ligula vestibulum. Varius scelerisque
            eget consequat tristique bibendum aliquam sit. Nibh semper euismod
            dictum odio urna facilisis curabitur facilisis.
          </p>
          <div className="relative inline-flex items-center justify-center gap-[10px] rounded-[15px] py-[15px] [background:linear-gradient(180deg,rgb(37,97,189)_0%,rgb(64.2,122.5,211.44)_34%,rgb(89.8,146.5,232.56)_66%,rgb(117,172,255)_100%)] md:w-[340px]">
            <button className="relative mt-[-1.00px] w-fit text-[16px] font-medium leading-[normal] tracking-[0] text-white [font-family:'Poppins-Medium',Helvetica]">
              Bergabung ke SDN Tegalrejo I
            </button>
          </div> */}
          {/* Mobile devices sejarah, visi misi, sosmed*/}
          {/* <div className="relative md:hidden">
            <Sejarah />
          </div> */}
          <div className="relative md:hidden">
            <BeritaTerbaru />
          </div>
          <div className="relative md:hidden">
            <div className="text-black relative flex items-center justify-center p-5 text-center text-[28px] font-bold leading-[normal] [font-family:'Nunito_Sans-Bold',Helvetica]">
              Visi dan Misi SD Muhammadiyah Karangkajen
            </div>
            <Visi />
          </div>
        </div>
        {/* <div className="relative hidden md:block">
          <div className="relative h-[400px] w-[400px] overflow-hidden rounded-full bg-gradient-to-b from-blue-300 to-blue-700">
            <img className="mt-40" src={siswa} alt="Siswa" />
          </div>
        </div> */}
      </div>

      {/* Mobile devices img */}
      {/* <div className="mt-5 p-[65px] md:hidden">
        <div className="relative h-[450px] w-[450px] overflow-hidden rounded-full bg-gradient-to-b from-blue-300 to-blue-700">
          <img className="mt-40" src={siswa} alt="Siswa" />
        </div>
      </div> */}
    </>
  );
}
export default Welcome;
