import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

const TableMediaVideo = () => {
  const navigate = useNavigate();
  const [galeries, setGaleries] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteGaleriesId, setDeleteGaleriesId] = useState(null);

  useEffect(() => {
    const fetchGaleriesData = async () => {
      try {
        const response = await axios.get(
          `/api_s/galeries?type=video`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        setGaleries(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.log(error.response);
        toast.error("Error fetching data", error);
      }
    };

    fetchGaleriesData();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-video/${id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteGaleriesId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `/api_s/galeries/${deleteGaleriesId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      toast.success("Berhasil Hapus Galery");
      const response = await axios.get(
        `/api_s/galeries?type=video`,
        {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      setGaleries(response.data.data);
    } catch (error) {
      toast.error(
        "Gagal Hapus Galery" + (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div className="mb-4">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="py-3 px-6 text-center">No</th>
            <th className="py-3 px-6 text-center">Judul</th>
            <th className="py-3 px-6 text-center">Video</th>
            <th className="py-3 px-6 text-center">Kategori</th>
            <th className="py-3 px-6 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {galeries.map((item, index) => (
            <tr className="boder-b border-gray-200 hover:bg-gray-100">
              <td className="py-3 px-6 text-center">{index + 1}</td>
              <td className="py-3 px-6 text-center">{item.title}</td>
              <td className="py-3 px-6 text-center">
          <iframe
            src={item.url}
            title={item.title}
            className="w-full h-64"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </td>
              <td className="py-3 px-6 text-center">{item.GaleryCategory.name}</td>
              <td className="py-3 px-6 text-center"><div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 transform text-red-500 hover:scale-110 hover:text-red-600"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div></td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Galery
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus gallery video ini?</p>
            <div className="flex justify-end gap-4">
              <button
                className="rounded bg-gray-500 py-2 px-4 text-white hover:bg-gray-700"
                onClick={handleCancelDelete}
              >Batal
              </button>
              <button onClick={handleConfirmDelete} className="rounded bg-red-500 py-2 px-4 text-white hover:bg-red-700">
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TableMediaVideo;
