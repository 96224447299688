import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdArrowRoundForward, IoMdCamera } from "react-icons/io";
import DetailsFoto from "../galeri/foto/DetailsFoto";

function GaleriFoto({ photos }) {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handleClosePopup = () => {
    setSelectedPhoto(null);
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  return (
    <div className="px-3">
      <div className="mb-6 text-center text-3xl font-bold text-blue-700">
        Galeri Foto Kegiatan
      </div>
      <Swiper
        slidesPerView={4}
        spaceBetween={20}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          el: ".swiper-pagination-custom",
        }}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 20 },
          420: { slidesPerView: 1, spaceBetween: 20 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 20 },
          1024: { slidesPerView: 4, spaceBetween: 20 },
        }}
        className="mySwiper w-full max-w-screen-3xl"
        modules={[Pagination, Autoplay]}
      >
        {photos?.slice(0, 6).map((photo, index) => (
          <SwiperSlide key={index} className="md:mx-2">
            <div className="group relative mx-auto w-full max-w-md rounded-xl">
              <img
                className="h-64 w-full rounded-t-xl object-cover transition duration-300 group-hover:brightness-50 group-hover:filter"
                src={photo.url.split(",")[0]}
                alt={photo.title}
              />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <button
                  onClick={() => handlePhotoClick(photo)}
                  className="rounded-full bg-white p-4 shadow-lg transition-colors duration-300 hover:bg-blue-100"
                >
                  <IoMdCamera className="h-7 w-7 text-blue-600" />
                </button>
              </div>
              <div className="p-4">
                <div className="mb-2 flex items-center">
                  <FaCalendarAlt className="mr-2 h-4 w-4 text-blue-600" />
                  <p className="text-sm font-semibold text-blue-800">
                    {formatDate(photo.createdAt)}
                  </p>
                </div>
                <h3 className="mt-2 text-lg font-bold text-blue-700">
                  {photo.title}
                </h3>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-pagination-custom mt-4 flex items-center justify-center"></div>
      </Swiper>

      <div className="mt-4 flex items-center justify-center">
        <Link
          to={"/foto"}
          className="inline-flex items-center rounded-[10px] bg-blue-600 px-4 py-2 text-white shadow-lg transition duration-300 hover:bg-blue-700"
        >
          Lihat Selengkapnya
          <IoMdArrowRoundForward className="ml-1 mt-1" />
        </Link>
      </div>
      <div className="swiper-pagination-custom mt-8 flex items-center justify-center"></div>

      {selectedPhoto && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-70">
          <DetailsFoto photo={selectedPhoto} onClose={handleClosePopup} />
        </div>
      )}
    </div>
  );
}

export default GaleriFoto;
