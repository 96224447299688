import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import imgDefault from "../../../assets/img/logo.png";

function Sambutan() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api_s/abouts?title=Sambutan`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      const sambutan = response.data.data[0];
      if (sambutan) {
        setTitle(sambutan.title);
        setBody(sambutan.body);
        setImageUrl(sambutan.image_url);
      }

      const resources = await axios.get(
        `/api_s/resources?position_name=kepala sekolah`,
        {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      const resourcesData = resources.data.data[0];
      if (resourcesData) {
        setName(resourcesData.name);
        setPosition(resourcesData.Position ? resourcesData.Position.name : "");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          {title || "Sambutan"} {position || "Kepala Sekolah"}
        </span>
      </h3>
      <div className="card mt-4 flex flex-col border-2 px-3 py-3 shadow-md md:flex-row">
        <div className="md:mr-4">
          <div className="flex items-center justify-center text-center">
            <img
              src={imageUrl || imgDefault}
              alt={title}
              className="h-[250px] w-auto"
            />
          </div>
          <div className="mt-2">
            <p className="flex items-center justify-center font-bold">
              {name || "Nama Kepala Sekolah"}
            </p>
            <p className="flex items-center justify-center">
              {position || "Kepala Sekolah"}
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between overflow-hidden ">
          <div className="mt-4 w-[225px] break-words text-justify md:mt-0 md:w-auto">
            {body
              ? parse(body)
              : "Selamat datang di website SD Muhammadiyah Karangkajen"}
          </div>
          <div className="mt-4 flex items-end justify-end">
            <div className="text-left">
              <p className="mt-2 font-bold">SD Muhammadiyah Karangkajen</p>
              <br />
              <br />
              <br />
              <p className="font-bold">{name || "Nama Kepala Sekolah"}</p>
              <p>{position || "Kepala Sekolah"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sambutan;
