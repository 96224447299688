import React, { useEffect, useState } from "react";
import banner from "assets/img/banner.png";
import Header from "components/user/Header";
import Footer from "components/user/Footer";
import CardSambutan from "components/user/profile/sambutan/CardSambutan";
import axios from "axios";
import { toast } from "react-toastify";
import BeritaPopuler from "components/user/BeritaPopuler";

function Sambutan() {
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [headerImage, setHeaderImage] = useState("");

  useEffect(() => {
    fetchTitle();
    fetchHeader();
  }, []);

  const fetchTitle = async () => {
    try {
      const response = await axios.get(`/api_s/abouts?title=Sambutan`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      const sambutan = response.data.data[0];
      if (sambutan) {
        setTitle(sambutan.title);
      }

      const resources = await axios.get(`/api_s/resources?position_id=4`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      const resourcesData = resources.data.data[0];
      if (resourcesData) {
        setPosition(resourcesData.Position ? resourcesData.Position.name : "");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const fetchHeader = async () => {
    try {
      const response = await axios.get(`/api_s/header`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });

      // Check if response contains the expected structure
      if (response.data && response.data.data) {
        const imageData = response.data.data;

        // Assuming imageData could be an array of objects, extract the URL properly
        if (Array.isArray(imageData)) {
          // Adjust this logic based on your actual data structure
          setHeaderImage(imageData[4]?.image_url || ""); // Set to an empty string if not found
        } else if (typeof imageData === "string") {
          setHeaderImage(imageData);
        } else {
          toast.error("Invalid image data format.");
        }
      } else {
        toast.error("No data found in the response.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  return (
    <>
      <Header />
      <div className="relative top-[35px] mt-[30px] inline-flex h-auto w-full">
        {headerImage ? ( // Render the image only if headerImage is available
          <img
            className="banner h-auto w-full"
            src={headerImage}
            alt="banner"
          />
        ) : (
          // Optionally, you can display a message or an empty div if no header image is available
          <div className="banner-placeholder flex h-auto w-full items-center justify-center bg-gray-200">
            <p className="text-gray-600">No Header Image Available</p>
          </div>
        )}
        <div className="max-w-500 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center font-bold text-white">
          <p className="text-xl md:text-4xl">
            {title || "Sambutan"} {position || "Kepala Sekolah"} <br /> SD
            Muhammadiyah Karangkajen
          </p>
        </div>
      </div>
      <div className="row mt-10 flex flex-wrap gap-6 p-4">
        <div className="w-full lg:w-[68%]">
          <CardSambutan />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Sambutan;
