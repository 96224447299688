import React from "react";
import { useNavigate } from "react-router-dom";
import TableMediaVideo from "./components/TableMediaVideo";

function Video() {
  const navigate = useNavigate();

  const handleLihatKategori = () => {
    navigate("/admin/kategorivideo");
  };

  const handleTambahVideo = () => {
    navigate("/admin/add-video");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <button onClick={handleTambahVideo} className="mr-2 rounded bg-blue-500 px-4 py-2 text-white">
            Tambah Video
          </button>
          <button
            onClick={handleLihatKategori}
            className="mr-2 rounded bg-green-500 px-4 py-2 text-white"
          >
            Lihat Kategori
          </button>
        </div>
      </div>
     <TableMediaVideo></TableMediaVideo>
    </div>
  );
}
export default Video;
