import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { toast } from "react-toastify";

const VisiMisi = () => {
  const [visi, setVisi] = useState("");
  const [idVisi, setIdVisi] = useState("");
  const [titleVisi, setTitleVisi] = useState("");
  const [imageUrlVisi, setImageUrlVisi] = useState("");
  const [misi, setMisi] = useState("");
  const [idMisi, setIdMisi] = useState("");
  const [titleMisi, setTitleMisi] = useState("");
  const [imageUrlMisi, setImageUrlMisi] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const visionResponse = await axios.get(`/api_s/abouts?title=Visi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const missionResponse = await axios.get(`/api_s/abouts?title=Misi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const vision = visionResponse.data.data[0];
        const mission = missionResponse.data.data[0];

        //data visi
        setIdVisi(vision?.id || "");
        setTitleVisi(vision?.title || "");
        setVisi(vision?.body || "");
        setImageUrlMisi(mission?.image_url || "");

        //data misi
        setIdMisi(mission?.id || "");
        setTitleMisi(mission?.title || "");
        setMisi(mission?.body || "");
        setImageUrlVisi(vision?.image_url || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-visi-misi/${id}`);
  };

  const handleAddClick = (type) => {
    navigate(`/admin/add-visi-misi/new?type=${type}`);
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="mx-auto mt-8 p-5">
      {!titleVisi && (
        <div className="mb-4">
          <button
            onClick={() => handleAddClick("visi")}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white"
          >
            Tambah Visi
          </button>
        </div>
      )}
      {!titleMisi && (
        <div className="mb-4">
          <button
            onClick={() => handleAddClick("misi")}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white"
          >
            Tambah Misi
          </button>
        </div>
      )}
      {titleVisi && (
        <div className="relative mx-auto mb-4 w-full rounded-lg border border-gray-600 bg-gray-200 p-6">
          <h2 className="mb-4 flex items-center justify-center text-center text-xl font-bold uppercase">
            {titleVisi}
          </h2>
          {imageUrlVisi && (
            <div className="flex justify-center">
              <img src={imageUrlVisi} alt="visi" className="h-24 w-24" />
            </div>
          )}
          <div className="break-words">{parse(visi)}</div>
          <FaEdit
            className="absolute right-2 top-2 h-5 w-5 cursor-pointer text-gray-800"
            onClick={() => handleEditClick(idVisi)}
          />
        </div>
      )}
      {titleMisi && (
        <div className="relative mx-auto mt-10 rounded-lg border border-gray-600 bg-gray-200 p-6">
          <h2 className="mb-4 flex items-center justify-center text-center text-xl font-bold uppercase">
            {titleMisi}
          </h2>
          {imageUrlMisi && (
            <div className="flex justify-center">
              <img src={imageUrlMisi} alt="misi" className="h-24 w-24" />
            </div>
          )}
          <div className="break-words">{parse(misi)}</div>
          <FaEdit
            className="absolute right-2 top-2 h-5 w-5 cursor-pointer text-gray-800"
            onClick={() => handleEditClick(idMisi)}
          />
        </div>
      )}
    </div>
  );
};

export default VisiMisi;
