import React from "react";

const ButtonSambutan = ({ isEditing, handleSubmit, handleCancel, id }) => {
  return (
    <div className="mt-2 flex justify-end gap-4">
      {id && isEditing && (
        <button
          type="button"
          className="rounded bg-red-500 px-3 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
      )}
      {isEditing && (
        <button
          type="button"
          className="rounded bg-blue-500 px-3 py-2 font-bold text-white hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Simpan
        </button>
      )}
    </div>
  );
};

export default ButtonSambutan;
