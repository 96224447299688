import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AddCarousel = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState([]);

  const handleCancel = () => {
    navigate("/admin/carousel");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        toast.error(
          "Error Upload Gambar" +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `/api_s/carousels`,
        {
          title,
          image_url: imageUrl,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data.data);
      toast.success("Berhasil Tambah Carousel!");
      navigate("/admin/carousel");
    } catch (error) {
      toast.error(
        "Error Tambah Carousel: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          id="title"
          name="title"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="Judul"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-bold text-gray-800 dark:text-white">
          Gambar
        </label>
        {imageUrl && (
          <div className="mt-2">
            <img src={imageUrl} alt="title" className="h-auto w-auto" />
          </div>
        )}
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          className="mt-2 w-full rounded border px-3 py-2 dark:text-white"
          onChange={handleFileChange}
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>

      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Tambah
        </button>
      </div>
    </div>
  );
};

export default AddCarousel;
