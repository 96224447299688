import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const CardPopup = ({
  id,
  img,
  title,
  link,
  status,
  onDelete,
  onUpdateStatus,
}) => {
  const navigate = useNavigate();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deletePopupId, setDeletePopupId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(status);

  const handleEditClick = () => {
    navigate(`/admin/edit-popup/${id}`);
  };

  const handleDeleteClick = () => {
    setShowDeletePrompt(true);
    setDeletePopupId(id);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/popups/${deletePopupId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      onDelete(deletePopupId);
      toast.success("Berhasil Hapus Popup");
    } catch (error) {
      toast.error(
        "Error Hapus Popup: " + (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const handleSwitchChange = async (newStatus) => {
    const statusChanged = await onUpdateStatus(id, newStatus, title, img);
    if (statusChanged) {
      setCurrentStatus(newStatus);
    }
  };

  return (
    <div className="m-2 overflow-hidden rounded bg-white shadow-lg dark:bg-gray-800">
      <div className="flex h-[150px] items-center justify-center">
        <img
          className="max-h-full w-auto object-cover"
          src={img}
          alt={title}
          onClick={handleEditClick}
        />
      </div>
      <div className="px-6 py-4">
        <div className="mb-2 text-xl font-bold dark:text-white">{title}</div>
        <p className="mb-2 text-sm text-gray-700 hover:text-blue-700 dark:text-gray-400">
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </p>
        <div className="mb-2 flex items-center justify-between">
          <div>
            <Switch
              onChange={handleSwitchChange}
              checked={currentStatus}
              height={18}
              width={36}
              handleDiameter={14}
            />
          </div>
          <div>
            <button
              onClick={handleDeleteClick}
              className="mr-2 transform text-red-500 hover:scale-110 hover:text-red-600"
            >
              <FaTrash className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>

      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Popup
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus popup ini?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardPopup;
