import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TambahTenagaPendidik = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image_url: "",
    position_id: "",
  });
  const [positions, setPositions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedType, setSelectedType] = useState(""); // State baru untuk menyimpan type yang dipilih

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get(`/api_s/positions`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setPositions(response.data.data);
      } catch (error) {
        console.log(error.response);
        toast.error("Error Fetching Jabatan");
      }
    };
    fetchPositions();
  }, []);

  const handleCancel = () => {
    navigate("/admin/tenaga-pendidik");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const data = new FormData();
    data.append("file", selectedFile);

    try {
      const response = await axios.post(`/api_s/upload/single`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });

      return response.data.data.Location;
    } catch (error) {
      console.log(error.response);
      toast.error("Gagal Upload Gambar");
      throw error;
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      position_id: "", // Reset jabatan saat type berubah
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const imageURL = await handleFileUpload();

      const postData = {
        ...formData,
        url: imageURL,
        type: "image",
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/resources`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Tambah Pendidik!");
      navigate("/admin/tenaga-pendidik");
    } catch (error) {
      console.log(error.response);
      toast.error(
        "Error Tambah Tenaga Pendidik: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Nama Pendidik
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Foto
        </label>
        {selectedFile && (
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Preview"
            className="ml-4 h-auto w-auto"
          />
        )}
        <input
          type="file"
          name="image"
          onChange={handleFileChange}
          className="w-full rounded-md px-2 py-2 dark:text-white"
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold text-gray-800 dark:text-white">
          Type Pendidik
        </label>
        <select
          id="type"
          name="type"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          value={selectedType}
          onChange={handleTypeChange}
        >
          <option value="">Pilih Type Pendidik</option>
          <option value="teacher">Guru</option>
          <option value="employee">Karyawan</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold text-gray-800 dark:text-white">
          Jabatan
        </label>
        <select
          id="jabatan"
          name="position_id"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          value={formData.position_id}
          onChange={handleChange}
        >
          <option value="" disabled>
            Pilih Jabatan
          </option>
          {positions
            .filter((position) => position.type === selectedType)
            .map((position) => (
              <option key={position.id} value={position.id}>
                {position.name}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Periode Aktif
        </label>
        <input
          type="text"
          name="periode"
          value={formData.periode}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Tambah
        </button>
      </div>
    </form>
  );
};

export default TambahTenagaPendidik;
