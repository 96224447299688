import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "components/admin/navbar";
import Sidebar from "components/admin/sidebar";
import Footer from "components/admin/footer/Footer";
import routes, {
  tampilan,
  konten,
  school,
  media,
  pengaturan,
  info,
} from "routes.js";
import NotFound from "components/user/NotFound";

// Combine all route categories
const allRoutes = [
  ...routes,
  ...tampilan,
  ...konten,
  ...school,
  ...media,
  ...pengaturan,
  ...info,
];

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Not Found");
  const [hasKontak, setHasKontak] = useState(false);
  const [hasProfile, setHasProfile] = useState(false);

  const currentPath = location.pathname;

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  useEffect(() => {
    getActiveRoute(allRoutes);
  }, [location.pathname]);

  useEffect(() => {
    // Fetch data to determine if there's existing contact or profile data
    const fetchData = async () => {
      try {
        const [kontakResponse, profileResponse] = await Promise.all([
          axios.get(`/api_s/contacts`, {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }),
          axios.get(`/api_s/schoolprofiles`, {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }),
        ]);

        setHasKontak(kontakResponse.data.data.length > 0);
        setHasProfile(profileResponse.data.data.length > 0);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response?.data?.message || error.message
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Show toast only when accessing "add-profile" or "add-kontak"
    if (currentPath.includes("add-kontak") && hasKontak) {
      toast.info("Data kontak sudah ada. Silakan edit data kontak yang ada.");
    }
    if (currentPath.includes("add-profile-sekolah") && hasProfile) {
      toast.info(
        "Data profile sekolah sudah ada. Silakan edit data profile sekolah yang ada."
      );
    }
  }, [currentPath, hasKontak, hasProfile]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Not Found";

    routes.forEach((route) => {
      const pathToMatch =
        route.layout + "/" + route.path.replace(":id", "[^/]+");

      const regex = new RegExp(pathToMatch);

      if (window.location.href.match(regex)) {
        setCurrentRoute(route.name);
        activeRoute = route.name;
      }
    });

    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        // Check for access restrictions here
        if (prop.path === "add-kontak" && hasKontak) {
          return (
            <Route
              path={`/${prop.path}`}
              element={<Navigate to="/admin/kontak" replace />}
              key={key}
            />
          );
        }

        if (prop.path === "add-profile-sekolah" && hasProfile) {
          return (
            <Route
              path={`/${prop.path}`}
              element={<Navigate to="/admin/profile-sekolah" replace />}
              key={key}
            />
          );
        }

        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"SD Muhammadiyah Karangkajen"}
              brandText={currentRoute}
              secondary={getActiveNavbar(allRoutes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(allRoutes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
