import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const EditPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    category_id: "",
    status: "",
    date: "",
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/api_s/postcategories`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setCategories(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/api_s/posts/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const postData = response.data.data;
        const date = new Date(postData.date);
        const formattedDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}T${String(
          date.getHours()
        ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
        setFormData({
          title: postData.title,
          description: postData.description,
          image: postData.image,
          category_id: postData.category_id,
          status: String(postData.status),
          date: formattedDate,
        });
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPost();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadData = new FormData();
        uploadData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, uploadData, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          image: response.data.data.Location,
        }));
      } catch (error) {
        toast.error(
          "Error Upload Image" +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`/api_s/posts/${id}`, formData, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      console.log(response.data);
      toast.success("Berhasil Edit Postingan!");
      navigate("/admin/posts");
    } catch (error) {
      toast.error(
        "Error Edit Postingan: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Deskripsi
        </label>
        <CKEditor
          editor={ClassicEditor}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
          data={formData.description}
          onChange={handleDescriptionChange}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Gambar
        </label>
        {formData.image && (
          <img
            src={formData.image}
            alt={formData.title}
            className="mb-2 h-auto w-auto"
          />
        )}
        <input
          type="file"
          name="image"
          onChange={handleFileChange}
          className="w-full rounded-md px-2 py-2 dark:text-white"
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Tanggal
        </label>
        <input
          type="datetime-local"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 p-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Kategori
        </label>
        <select
          name="category_id"
          value={formData.category_id}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Kategori
          </option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Status
        </label>
        <select
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="">Pilih Status</option>
          <option value="true">active</option>
          <option value="false">non active</option>
        </select>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={() => navigate("/admin/posts")}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Edit
        </button>
      </div>
    </form>
  );
};

export default EditPost;
