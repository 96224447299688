import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  MdOutlineAccessTimeFilled,
  MdPerson,
  MdRemoveRedEye,
} from "react-icons/md";
import { toast } from "react-toastify";
import ShareSosmed from "./Share";

function Details() {
  const { slug } = useParams();
  const [detail, setDetail] = useState("");

  const fetchDetail = async () => {
    try {
      const response = await axios.get(`/api_s/posts?slug=${slug}`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setDetail(response.data.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchDetail();

    const intervalId = setInterval(fetchDetail, 1000);

    return () => clearInterval(intervalId);
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  return (
    <div className="relative flex flex-col">
      <h2 className="text-[32px] font-bold">{detail.title}</h2>
      <div className="my-2 flex items-center">
        <MdOutlineAccessTimeFilled className="mr-1 h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-400">
          {formatDate(detail.date)}
        </span>
        <MdPerson className="ml-2 mr-1 h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-400">{detail.User?.name}</span>
        <MdRemoveRedEye className="ml-2 mr-1 h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-400">{detail.views}</span>
      </div>
      <div className="mt-4 flex items-center justify-center">
        <img
          className="h-auto w-full rounded-md"
          src={detail.image || "https://placehold.co/600x400?text=No-Image"}
          alt={detail.title}
        />
      </div>
      <div className="mt-3 text-justify">
        {typeof detail.description === "string"
          ? parse(detail.description)
          : detail.description}
      </div>
      <ShareSosmed title={detail.title} />
    </div>
  );
}

export default Details;
