import axios from "axios";
import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import EditKategoriFoto from "./EditKategoriFoto";

const TableKategoriFoto = ({ categoriesphoto, fetchCategoriesPhoto }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const handleEditClick = (id) => {
    setSelectedCategoryId(id);
    setShowEditPopup(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteCategoryId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `/api_s/galerycategories/${deleteCategoryId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      toast.success("Berhasil Hapus Kategori Foto");
      fetchCategoriesPhoto();
    } catch (error) {
      toast.error("Gagal Hapus Kategori Foto");
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div>
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Kategori</th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {categoriesphoto.map((item, index) => (
            <tr
              key={index}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="whitespace-nowrap px-6 py-3 text-center">
                {index + 1}
              </td>
              <td className="px-6 py-3 text-center">{item.name}</td>
              <td className="px-6 py-3 text-center">
                <div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button className="mr-2 w-4 transform text-red-400 hover:scale-110 hover:text-red-600">
                    <FaTrash
                      onClick={() => handleDeleteClick(item.id)}
                      className="h-4 w-4"
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showEditPopup && (
        <EditKategoriFoto
          onClose={() => setShowEditPopup(false)}
          categoryId={selectedCategoryId}
          fetchCategoriesPhoto={fetchCategoriesPhoto}
        />
      )}
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="shawdow-md rounded bg-white p-4">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Kategori Foto
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus kategori foto ini?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TableKategoriFoto;
