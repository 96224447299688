import React, { useEffect, useState } from "react";
import logo from "assets/img/logo.png";
import { MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Footer = () => {
  const [logos, setLogos] = useState([]);
  const [schoolProfile, setSchoolProfile] = useState({
    address: "Jalan Gajah Mada No.7B, Kurahan, Bantul, Kec.Bantul",
    city: "Kabupaten Bantul",
    province: "Daerah Istimewa Yogyakarta",
    postalCode: "55711",
  });

  const [contact, setContact] = useState({
    email: "smp.muh.bantul@gmail.com",
    phone: "(0274) 367043",
  });

  //get logo by id 1
  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get("/api_s/logo", {
          headers: {
            db_name: process.env.RECAT_APP_DB_NAME,
          },
        });
        const logoData = response.data.data || [];

        //Default logo
        const defaultLogos = [{ id: 1, url: logo }];

        //Check if the API response contains logo with id 1
        const logosDefaults = defaultLogos.map((defaultLogo) => {
          const dataLogo = logoData.find((logo) => logo.id === defaultLogo.id);
          return dataLogo || defaultLogo;
        });

        setLogos(logosDefaults);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setLogos([{ id: 1, url: logo }]);
      }
    };

    fetchLogoData();
  }, []);

  //get address, city, province, phone & email
  useEffect(() => {
    axios
      .get("/api_s/schoolprofiles")
      .then((response) => {
        const profileData = response.data?.data[0];
        if (profileData) {
          setSchoolProfile((prevState) => ({
            ...prevState,
            address: profileData.address,
            city: profileData.City.name,
            province: profileData.Province.name,
            postalCode: profileData.postal_code,
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching school profile:", error);
      });

    axios
      .get("/api_s/contacts")
      .then((response) => {
        const contactData = response.data?.data[0];
        if (contactData) {
          setContact((prevState) => ({
            ...prevState,
            email: contactData.email || prevState.email,
            phone: contactData.phone || prevState.phone,
          }));
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.message);
      });
  }, []);

  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-200 p-2 text-white">
      <div className="container mx-auto hidden px-6 py-8 md:block">
        <div className="grid grid-cols-1 gap-6 text-center sm:grid-cols-2 sm:gap-y-10 md:text-left lg:grid-cols-5">
          <div className="sm:col-span-2">
            <div className="flex justify-center md:justify-start">
              {/* <img className="h-24" src={logo} alt="Company Logo" /> */}
              {logos.map((logo) => (
                <img key={logo.id} className="h-24" src={logo.url} alt="Logo" />
              ))}
            </div>
            <h1 className="mt-4 max-w-lg text-xl font-semibold tracking-tight text-gray-100 xl:text-2xl">
              SD Muhammadiyah Karangkajen
            </h1>
            <div className="mt-3">
              <p>{schoolProfile.address},</p>
              <p>
                {schoolProfile.city}, {schoolProfile.province}
              </p>
              <p>{schoolProfile.postalCode}</p>
            </div>
            <p className="my-3">{contact.email}</p>
            <p className="flex items-center justify-center md:justify-start">
              <MdPhone className="mr-2" /> {contact.phone}
            </p>
          </div>

          <div>
            <p className="font-semibold text-gray-100">
              SD Muhammadiyah Karangkajen
            </p>

            <div className="mt-5 flex flex-col space-y-2">
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="/visi-misi">Visi Misi</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Sarana dan Prasarana</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Struktur Organisasi</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Kepala Madrasah</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Tenaga Kependidikan</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Ekstrakurikuler</Link>
              </p>
            </div>
          </div>

          <div>
            <p className="font-semibold text-gray-100">Website Terkait</p>

            <div className="mt-5 flex flex-col space-y-2">
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">PPDB</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Perpustakaan</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="https://jogjamadrasahdigital.net/" target="_blank">
                  Jogja Madrasah Digital
                </Link>
              </p>
            </div>
          </div>

          <div>
            <p className="font-semibold text-gray-100">Navigasi</p>

            <div className="mt-5 flex flex-col space-y-2">
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="/profile-sekolah">Profil Sekolah</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="/berita">Berita</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="">Alumni</Link>
              </p>
              <p className="transition-colors duration-300 hover:cursor-pointer hover:text-blue-800 hover:underline dark:hover:text-blue-700">
                <Link to="/foto">Galeri</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center font-sans md:mt-6 ">
        &copy; All Rights Reserved SD Muhammadiyah Karangkajen 2024 | Pengelola
        Situs Web
      </p>
    </div>
  );
};

export default Footer;
