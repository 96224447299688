import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";

const testimonials = [
  {
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    img: "https://via.placeholder.com/150",
    name: "Muhammad Rian Ardianto",
    position: "Pemain Bulutangkis Nasional Indonesia",
  },
  {
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    img: "https://via.placeholder.com/150",
    name: "Ziko Harnadi",
    position: "Content Creator & Drifter Nasional Indonesia",
  },
  {
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    img: "https://via.placeholder.com/150",
    name: "Sarah Lee",
    position: "UX Designer",
  },
  {
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    img: "https://via.placeholder.com/150",
    name: "John Doe",
    position: "Sofware Engineer",
  },
  {
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    img: "https://via.placeholder.com/150",
    name: "Jane Smith",
    position: "Product Manager",
  },
];

function Testimoni() {
  return (
    <div className="relative">
      <div className="flex flex-col p-4 text-[30px] font-bold italic text-[#4E94FF]">
        Testimoni Alumni
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={32}
        loop={true}
        centeredSlides={true}
        pagination={{
          clickable: true,
          el: ".swiper-pagination-custom",
        }}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        breakpoints={{
          640: { slidesPerView: 1, spaceBetween: 32 },
          768: { slidesPerView: 2, spaceBetween: 32 },
          1024: { slidesPerView: 3, spaceBetween: 32 },
        }}
        modules={[Pagination, Autoplay]}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="mx-auto w-full max-w-md rounded-xl border border-gray-300 bg-white p-6">
              <div className="mb-4">
                <p className="text-base leading-6 text-[#331B3B]">
                  "{testimonial.text}"
                </p>
              </div>
              <div className="flex items-center">
                <img
                  className="mr-4 h-16 w-16 rounded-full"
                  src={testimonial.img}
                  alt={testimonial.name}
                />
                <div>
                  <h5 className="font-bold font-medium text-gray-900">
                    {testimonial.name}
                  </h5>
                  <span className="text-sm italic text-gray-500">
                    {testimonial.position}
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-pagination-custom mt-5 flex items-center justify-center"></div>
      </Swiper>
    </div>
  );
}

export default Testimoni;
