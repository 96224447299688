import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditPopup = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [status, setStatus] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();

  const handleCancel = () => {
    navigate("/admin/popup");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        toast.error(
          "Error Upload Gambar" +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  useEffect(() => {
    const fetchPopupData = async () => {
      try {
        const response = await axios.get(`/api_s/popups/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const popup = response.data.data;
        setTitle(popup.title);
        setLink(popup.link);
        setStatus(popup.status);
        setImageUrl(popup.image_url);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPopupData();
  }, [id]);

  const checkActivePopup = async () => {
    try {
      const response = await axios.get(`/api_s/popups?status=1`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      return response.data.data.length > 0;
    } catch (error) {
      toast.error("Error checking active popups.");
      return false;
    }
  };

  const handleSubmit = async () => {
    if (status === "true") {
      const hasActivePopup = await checkActivePopup();
      if (hasActivePopup) {
        toast.error(
          "Sudah ada popup dengan status aktif. Tidak bisa mengubah status menjadi aktif."
        );
        return;
      }
    }
    try {
      const response = await axios.put(
        `/api_s/popups/${id}`,
        {
          title,
          image_url: imageUrl,
          link,
          status,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data);
      toast.success("Berhasil Edit Popup!");
      navigate("/admin/popup");
    } catch (error) {
      toast.error(
        "Error Edit Popup: " + (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Gambar
        </label>
        {imageUrl && (
          <img src={imageUrl} alt={title} className="h-auto w-auto" />
        )}
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          className="mt-2 w-full rounded border px-3 py-2 dark:text-white"
          onChange={handleFileChange}
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>

      <div className="mb-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          id="title"
          name="title"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="Judul"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Link
        </label>
        <input
          type="text"
          id="link"
          name="link"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="https://example.com"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Status
        </label>
        <select
          id="status"
          name="status"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="">Pilih Status</option>
          <option value="true">active</option>
          <option value="false">non active</option>
        </select>
      </div>

      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={handleSubmit}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default EditPopup;
