import React from "react";
import { useNavigate } from "react-router-dom";
import TableLogo from "./components/TableLogo";

function Logo() {
  const navigate = useNavigate();

  const handleTambahClick = () => {
    navigate("/admin/add-logo");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-end">
        <div>
          <button
            onClick={handleTambahClick}
            className="mr-2 rounded bg-blue-500 px-4 py-2 text-white"
          >
            Tambah Logo
          </button>
        </div>
      </div>
      <TableLogo />
    </div>
  );
}

export default Logo;
