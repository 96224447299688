import React, { useState, useEffect } from "react";
import axios from "axios";
import Maps from "./Maps";
import phone from "../../../../assets/img/icon-sosmed/phone.png";
import email from "../../../../assets/img/icon-sosmed/gmail.png";

function CardKontakSekolah() {
  const [schoolData, setSchoolData] = useState({
    school_name: "",
  });

  const [schoolContact, setSchoolContact] = useState({
    phone: "",
    email: "",
  });

  useEffect(() => {
    const fetchDataSekolah = async () => {
      try {
        const response = await axios.get(`/api_s/schoolprofiles`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const data = response.data.data[0];
        setSchoolData(data);
      } catch (error) {
        console.log(error.response);
      }
    };

    fetchDataSekolah();
  }, []);

  useEffect(() => {
    const fetchKontakSekolah = async () => {
      try {
        const response = await axios.get(`/api_s/contacts`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const contact = response.data.data[0];
        setSchoolContact(contact || {});
      } catch (error) {
        console.log(error.response);
      }
    };

    fetchKontakSekolah();
  }, []);

  return (
    <div className="mb-8">
      <h3 className="headers text-center text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Kontak {schoolData.school_name || "SD Muhammadiyah Karangkajen"}
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="w-full px-6 py-4">
          <Maps />
        </div>
      </div>
      <br></br>
      <h3 className="headers text-center text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Hubungi Kami
        </span>
      </h3>
      <div className="mt-5 flex w-full overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="w-full px-6 py-4">
          <div className="flex justify-center">
            <div className="flex items-center space-x-4">
              <a
                href={`tel:${schoolContact.phone}`}
                target="_blank"
                rel="noopener noreferrer"
                title="Phone"
                className="hover:text-blue-500"
              >
                <img className="mr-2 h-12 w-12" src={phone} alt="Phone" />
              </a>
              <a
                href={`mailto:${schoolContact.email}`}
                target="_blank"
                rel="noopener noreferrer"
                title="Email"
                className="hover:text-red-500"
              >
                <img className="mr-2 h-10 w-12" src={email} alt="Email" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardKontakSekolah;
