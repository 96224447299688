import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import parse from "html-react-parser";

function CardBerita() {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`/api_s/posts?status=1`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const sortedPosts = response.data.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setPosts(sortedPosts);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/api_s/postcategories`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setCategories(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPosts();
    fetchCategories();
  }, []);

  const handleCategoryFilter = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const filteredPosts = selectedCategory
    ? posts.filter((posts) => posts.category_id === parseInt(selectedCategory))
    : posts;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const handleReadMore = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Berita
        </span>
      </h3>
      <div className="mt-5">
        <div className="mb-4 flex flex-wrap">
          <button
            onClick={() => handleCategoryFilter("")}
            className={`mb-2 mr-2 rounded-md border px-2 py-1 ${
              selectedCategory === ""
                ? "border-2 border-blue-500 bg-blue-500 text-white"
                : "border-2 border-gray-300 text-gray-700"
            }`}
          >
            Semua
          </button>
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => handleCategoryFilter(category.id)}
              className={`mb-2 mr-2 rounded-md border px-2 py-1 ${
                category.id === selectedCategory
                  ? "border-2 border-blue-500 bg-blue-500 text-white"
                  : "border-2 border-gray-300 text-gray-700"
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
          {filteredPosts.map((post) => (
            <div
              key={post.id}
              className="flex flex-col justify-between rounded-md"
            >
              <div>
                <div className="relative flex h-[200px] items-center justify-center overflow-hidden rounded-t-md">
                  <img
                    className="w-full transform object-cover transition-transform duration-300 hover:scale-110"
                    src={post.image}
                    alt={post.title}
                  />
                  <div className="absolute bottom-0 left-0 rounded-bl-md rounded-tr-md bg-[#2561BD] px-2 py-1 text-white">
                    <p className="text-xs">{formatDate(post.date)}</p>
                  </div>
                </div>
                <div className="flex-grow p-2">
                  <Link to={`/berita/${post.slug}`}>
                    <div className="mb-2 text-justify text-xl font-bold text-blue-800 hover:text-blue-700">
                      {truncateText(post.title, 50)}
                    </div>
                  </Link>
                  <p className="text-justify text-base text-gray-700">
                    {parse(truncateText(post.description, 200))}
                  </p>
                </div>
              </div>
              <div className="p-2">
                <Link
                  to={`/berita/${post.slug}`}
                  onClick={handleReadMore}
                  className="inline-block rounded-[10px] bg-[#2561BD] px-3 py-2 text-center text-sm font-bold text-white"
                >
                  Baca Selengkapnya
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardBerita;
