import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import FormSchool from "./components/Form";
import ButtonSchool from "./components/Buttons";
import { useNavigate } from "react-router-dom";
import announce from "../../../assets/img/announce.png";

const ContactSchool = () => {
  const [schoolContact, setSchoolContact] = useState(null);
  const [update, setUpdate] = useState(false);
  const [originalContact, setOriginalContact] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchoolContact = async () => {
      try {
        const response = await axios.get(`/api_s/contacts`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const contact = response.data.data[0] || null;
        setSchoolContact(contact);
        setOriginalContact(contact);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolContact();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (update) {
      try {
        const config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `/api_s/contacts/${schoolContact.id}`,
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
          data: JSON.stringify(schoolContact),
        };

        const response = await axios.request(config);
        console.log(response.data);
        toast.success("Berhasil Edit Kontak Sekolah!");
        const updatedSchoolContact = await axios.get(`/api_s/contacts`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setSchoolContact(updatedSchoolContact.data.data[0]);
      } catch (error) {
        toast.error(
          "Error Edit Kontak Sekolah: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
    setUpdate((prev) => !prev);
  };

  const handleCancel = () => {
    setSchoolContact(originalContact);
    setUpdate(false);
  };

  const handleAddContact = () => {
    navigate("/admin/add-kontak");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative">
      {!schoolContact ? (
        <div className="flex h-screen items-center justify-center">
          <div className="text-center">
            <img
              className="mx-auto mb-2 h-[390px]"
              src={announce}
              alt="announce"
            />
            <div className="mb-2 text-2xl font-bold dark:text-white">
              Data kontak masih kosong silakan isi data kontak terlebih dahulu
            </div>
            <button
              type="button"
              className="mt-2 rounded-md bg-blue-500 px-3 py-2 text-white"
              onClick={handleAddContact}
            >
              Isi Data Kontak
            </button>
          </div>
        </div>
      ) : (
        <>
          {!update && (
            <button
              type="button"
              className="absolute right-6 top-0 m-4 flex rounded-md bg-blue-500 px-3 py-2 text-white "
              onClick={() => setUpdate(true)}
            >
              Edit
            </button>
          )}
          <div className="p-10">
            <FormSchool
              schoolContact={schoolContact}
              handleChange={handleChange}
              update={update}
            />
            <ButtonSchool
              update={update}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default ContactSchool;
