import React from "react";
import {
  MdLock,
  MdViewColumn,
  MdMenu,
  MdWeb,
  MdLayers,
  MdTextFields,
  MdArticle,
  MdMenuBook,
  MdAccountTree,
  MdPerson,
  MdPhone,
  MdDashboard,
  MdImage,
  MdVideoLibrary,
  MdSchool,
  MdViewCarousel,
  MdStar,
} from "react-icons/md";
import { FaBullhorn } from "react-icons/fa";
import { BiSolidBookBookmark } from "react-icons/bi";
import { BsFillImageFill } from "react-icons/bs";
import { FaComments, FaUsers } from "react-icons/fa6";
import Dashboard from "views/admin/dashboard";
import Popup from "views/admin/popup";
import AddPopup from "views/admin/popup/components/AddPopup";
import EditPopup from "views/admin/popup/components/EditPopup";
import Text from "views/admin/text";
import AddText from "views/admin/text/components/AddText";
import EditText from "views/admin/text/components/EditText";
import Posts from "views/admin/posts";
import AddPost from "views/admin/posts/components/AddPosts";
import EditPosts from "views/admin/posts/components/EditPosts";
import Kategori from "views/admin/posts/kategori";
import MediaFoto from "views/admin/mediafoto";
import KategoriFoto from "views/admin/mediafoto/kategorifoto";
import AddFoto from "views/admin/mediafoto/components/AddMediaFoto";
import EditFoto from "views/admin/mediafoto/components/EditMediaFoto";
import MediaVideo from "views/admin/mediavideo";
import KategoriVideo from "views/admin/mediavideo/kategorivideo";
import AddVideo from "views/admin/mediavideo/components/AddMediaVideo";
import EditVideo from "views/admin/mediavideo/components/EditMediaVideo";
import VisiMisi from "views/admin/visi-misi";
import EditVisiMisi from "views/admin/visi-misi/components/EditVisiMisi";
import ProfileSekolah from "views/admin/profile-sekolah";
import Sambutan from "views/admin/sambutan";
import Kontak from "views/admin/kontak";
import Carousel from "views/admin/carousel";
import AddCarousel from "views/admin/carousel/components/AddCarousel";
import EditCarousel from "views/admin/carousel/components/EditCarousel";
import User from "views/admin/users";
import AddUser from "views/admin/users/components/AddUser";
import EditUser from "views/admin/users/components/EditUser";
import Pengumuman from "views/admin/pengumuman";
import AddPengumuman from "views/admin/pengumuman/components/AddPengumuman";
import EditPengumuman from "views/admin/pengumuman/components/EditPengumuman";
import ComingSoon from "views/admin/ComingSoon";
import Agenda from "views/admin/agenda";
import AddAgenda from "views/admin/agenda/components/AddAgenda";
import EditAgenda from "views/admin/agenda/components/EditAgenda";
import AddProfile from "views/admin/profile-sekolah/components/AddProfile";
import AddContact from "views/admin/kontak/components/AddContact";
import AddVisiMisi from "views/admin/visi-misi/components/AddVisiMisi";
import Medsos from "views/admin/medsos";
import AddMedsos from "views/admin/medsos/components/AddMedsos";
import Logo from "views/admin/logo";
import AddLogo from "views/admin/logo/components/AddLogo";
import EditLogo from "views/admin/logo/components/EditLogo";
import Header from "views/admin/header";
import AddHeader from "views/admin/header/components/AddHeader";
import EditHeader from "views/admin/header/components/EditHeader";
import TenagaPendidik from "views/admin/pendidik";
import AddJabatan from "views/admin/pendidik/jabatan";
import AddPendidik from "views/admin/pendidik/components/AddPendidik";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <Dashboard />,
  },
];

const tampilan = [
  {
    name: "Tema",
    layout: "/admin",
    path: "tema",
    icon: <MdWeb className="h-6 w-6" />,
    component: <ComingSoon />,
  },
  {
    name: "Menu Navbar",
    layout: "/admin",
    path: "navbar",
    icon: <MdMenu className="h-6 w-6" />,
    component: <ComingSoon />,
  },
  {
    name: "Header",
    layout: "/admin",
    icon: <BsFillImageFill className="h-6 w-6" />,
    path: "header",
    component: <Header />,
  },
  {
    name: "Tambah Header",
    layout: "/admin",
    path: "add-header",
    hidden: true,
    component: <AddHeader />,
  },
  {
    name: "Edit Header",
    layout: "/admin",
    path: "edit-header/:id",
    hidden: true,
    component: <EditHeader />,
  },
  {
    name: "Kolom",
    layout: "/admin",
    path: "kolom",
    icon: <MdViewColumn className="h-6 w-6" />,
    component: <ComingSoon />,
  },
  {
    name: "Logo",
    layout: "/admin",
    path: "logo",
    icon: <MdLock className="h-6 w-6" />,
    component: <Logo />,
  },
  {
    name: "Tambah Logo",
    layout: "/admin",
    path: "add-logo",
    hidden: true,
    component: <AddLogo />,
  },
  {
    name: "Edit Logo",
    layout: "/admin",
    path: "edit-logo/:id",
    hidden: true,
    component: <EditLogo />,
  },
  {
    name: "Favicon",
    layout: "/admin",
    path: "favicon",
    icon: <MdStar className="h-6 w-6" />,
    component: <ComingSoon />,
  },
];

const konten = [
  {
    name: "Gambar Popup",
    layout: "/admin",
    path: "popup",
    icon: <MdLayers className="h-6 w-6" />,
    component: <Popup />,
  },
  {
    name: "Carousel",
    layout: "/admin",
    path: "carousel",
    icon: <MdViewCarousel className="h-6 w-6" />,
    component: <Carousel />,
  },
  {
    name: "Tambah Carousel",
    layout: "/admin",
    path: "add-carousel",
    hidden: true,
    component: <AddCarousel />,
  },
  {
    name: "Edit Carousel",
    layout: "/admin",
    path: "edit-carousel/:id",
    hidden: true,
    component: <EditCarousel />,
  },
  {
    name: "Tambah Popup",
    layout: "/admin",
    path: "add-popup",
    hidden: true,
    component: <AddPopup />,
  },
  {
    name: "Edit Popup",
    layout: "/admin",
    path: "edit-popup/:id",
    hidden: true,
    component: <EditPopup />,
  },
  {
    name: "Text Berjalan",
    layout: "/admin",
    path: "text",
    icon: <MdTextFields className="h-6 w-6" />,
    component: <Text />,
  },
  {
    name: "Tambah Text Berjalan",
    layout: "/admin",
    path: "add-text",
    hidden: true,
    component: <AddText />,
  },
  {
    name: "Edit Text Berjalan",
    layout: "/admin",
    path: "edit-text/:id",
    hidden: true,
    component: <EditText />,
  },
  {
    name: "Postingan",
    layout: "/admin",
    icon: <MdArticle className="h-6 w-6" />,
    path: "posts",
    component: <Posts />,
  },
  {
    name: "Tambah Postingan",
    layout: "/admin",
    path: "add-posts",
    hidden: true,
    component: <AddPost />,
  },
  {
    name: "Edit Postingan",
    layout: "/admin",
    path: "edit-posts/:id",
    hidden: true,
    component: <EditPosts />,
  },
  {
    name: "Kategori Postingan",
    layout: "/admin",
    path: "kategori",
    hidden: true,
    component: <Kategori />,
  },
  {
    name: "Tenaga Pendidik",
    layout: "/admin",
    icon: <FaUsers className="h-6 w-6" />,
    path: "tenaga-pendidik",
    component: <TenagaPendidik />
  },
  {
    name: "Jabatan",
    layout: "/admin",
    path: "add-jabatan",
    hidden: true,
    component: <AddJabatan />
  },
  { 
    name: "Tambah Pendidik",
    layout: "/admin",
    path: "add-pendidik",
    hidden: true,
    component: <AddPendidik />
  }
];

const school = [
  {
    name: "Profile Sekolah",
    layout: "/admin",
    path: "profile-sekolah",
    icon: <MdSchool className="h-6 w-6" />,
    component: <ProfileSekolah />,
  },
  {
    name: "Tambah Profile Sekolah",
    layout: "/admin",
    path: "add-profile-sekolah",
    hidden: true,
    component: <AddProfile />,
  },
  {
    name: "Visi Misi",
    layout: "/admin",
    path: "visi-misi",
    icon: <MdMenuBook className="h-6 w-6" />,
    component: <VisiMisi />,
  },
  {
    name: "Tambah Visi Misi",
    layout: "/admin",
    path: "add-visi-misi/:id",
    hidden: true,
    component: <AddVisiMisi />,
  },
  {
    name: "Edit Visi",
    layout: "/admin",
    path: "edit-visi-misi/:id",
    hidden: true,
    component: <EditVisiMisi />,
  },
  {
    name: "Struktur Organisasi",
    layout: "/admin",
    path: "organisasi",
    icon: <MdAccountTree className="h-6 w-6" />,
    component: <ComingSoon />,
  },
  {
    name: "Sambutan",
    layout: "/admin",
    icon: <MdPerson className="h-6 w-6" />,
    path: "sambutan",
    component: <Sambutan />,
  },
  {
    name: "Kontak",
    layout: "/admin",
    icon: <MdPhone className="h-6 w-6" />,
    path: "kontak",
    component: <Kontak />,
  },
  {
    name: "Tambah Kontak",
    layout: "/admin",
    path: "add-kontak",
    hidden: true,
    component: <AddContact />,
  },
  {
    name: "Media Sosial",
    layout: "/admin",
    icon: <FaComments className="h-6 w-6" />,
    path: "medsos",
    component: <Medsos />,
  },
  {
    name: "Tambah Media Sosial",
    layout: "/admin",
    path: "add-medsos",
    hidden: true,
    component: <AddMedsos />,
  },
  
];

const media = [
  {
    name: "Galeri Foto",
    layout: "/admin",
    icon: <MdImage className="h-6 w-6" />,
    path: "foto",
    component: <MediaFoto />,
  },
  {
    name: "Tambah Media Foto",
    layout: "/admin",
    path: "add-photo",
    hidden: true,
    component: <AddFoto />,
  },
  {
    name: "Edit Media Foto",
    layout: "/admin",
    path: "edit-photo/:id",
    hidden: true,
    component: <EditFoto />,
  },
  {
    name: "Kategori Foto",
    layout: "/admin",
    path: "kategorifoto",
    hidden: true,
    component: <KategoriFoto />,
  },

  {
    name: "Galeri Video",
    layout: "/admin",
    icon: <MdVideoLibrary className="h-6 w-6" />,
    path: "video",
    component: <MediaVideo />,
  },
  {
    name: "Kategori Video",
    layout: "/admin",
    path: "kategorivideo",
    hidden: true,
    component: <KategoriVideo />,
  },
  {
    name: "Tambah Media Video",
    layout: "/admin",
    path: "add-video",
    hidden: true,
    component: <AddVideo />,
  },
  {
    name: "Edit Media Video",
    layout: "/admin",
    path: "edit-video/:id",
    hidden: true,
    component: <EditVideo />,
  },
];

const pengaturan = [
  {
    name: "User",
    layout: "/admin",
    path: "user",
    icon: <MdPerson className="h-6 w-6" />,
    component: <User />,
  },
  {
    name: "Tambah User",
    layout: "/admin",
    path: "add-user",
    hidden: true,
    component: <AddUser />,
  },
  {
    name: "Edit User",
    layout: "/admin",
    path: "edit-user/:id",
    hidden: true,
    component: <EditUser />,
  },
];

const info = [
  {
    name: "Pengumuman",
    layout: "/admin",
    path: "pengumuman",
    icon: <FaBullhorn className="h5 w-5" />,
    component: <Pengumuman />,
  },
  {
    name: "Tambah Pengumuman",
    layout: "/admin",
    path: "add-pengumuman",
    hidden: true,
    component: <AddPengumuman />,
  },
  {
    name: "Edit Pengumuman",
    layout: "/admin",
    path: "edit-pengumuman/:id",
    hidden: true,
    component: <EditPengumuman />,
  },
  {
    name: "Agenda",
    layout: "/admin",
    path: "agenda",
    icon: <BiSolidBookBookmark className="h-6 w-6" />,
    component: <Agenda />,
  },
  {
    name: "Tambah Agenda",
    layout: "/admin",
    path: "add-agenda",
    hidden: true,
    component: <AddAgenda />,
  },
  {
    name: "Edit Agenda",
    layout: "/admin",
    path: "edit-agenda/:id",
    hidden: true,
    component: <EditAgenda />,
  },
];

export default routes;
export { tampilan };
export { konten };
export { school };
export { media };
export { pengaturan };
export { info };
