import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaUserFriends } from "react-icons/fa";

const lessons = [
  {
    id: 1,
    lessonName: "Pelajaran 1",
    time: "Jam 1 - 2",
    class: "7A",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 2,
    lessonName: "Pelajaran 2",
    time: "Jam 2 - 3",
    class: "7B",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 3,
    lessonName: "Pelajaran 1",
    time: "Jam 1 - 2",
    class: "8A",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 4,
    lessonName: "Pelajaran 1",
    time: "Jam 1 - 2",
    class: "8B",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 5,
    lessonName: "Pelajaran 1",
    time: "Jam 2 - 3",
    class: "9A",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 6,
    lessonName: "Pelajaran 2",
    time: "Jam 1 - 2",
    class: "7B",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 7,
    lessonName: "Pelajaran 2",
    time: "Jam 2 - 3",
    class: "7B",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
  {
    id: 8,
    lessonName: "Pelajaran 2",
    time: "Jam 2 - 3",
    class: "7B",
    teacher: "Carina Deana Brilian, S.Pd",
    participants: 30,
    teacherImage: "https://via.placeholder.com/40",
  },
];

function FilterList({ filterOptions, selectedFilter, handleFilter }) {
  return (
    <div className="mb-4 flex flex-wrap gap-2">
      {filterOptions.map((option) => (
        <button
          key={option}
          onClick={() => handleFilter(option)}
          className={`rounded-full px-2 py-1 ${
            selectedFilter === option
              ? "bg-white text-blue-500"
              : "bg-transparant border-2 text-white"
          }`}
        >
          {option}
        </button>
      ))}
    </div>
  );
}

function Learn() {
  const [selectedFilter, setSelectedFilter] = useState("Semua");
  const [selectedClass, setSelectedClass] = useState("Kelas 7");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const handleClassFilterChange = (selectedClass) => {
    setSelectedClass(selectedClass);
  };

  const filterOptions = ["Semua", "Jam 1", "Jam 2", "Jam 3", "Jam 4", "Jam 5"];
  const classOptions = ["Kelas 7", "Kelas 8", "Kelas 9"];

  const filteredLessons = lessons.filter((lesson) => {
    if (selectedFilter === "Semua" && selectedClass === "") {
      return true;
    } else if (selectedFilter !== "Semua" && selectedClass === "") {
      return lesson.time.includes(selectedFilter);
    } else if (selectedFilter === "Semua" && selectedClass !== "") {
      return lesson.class.startsWith(selectedClass.replace("Kelas ", ""));
    } else {
      return (
        lesson.time.includes(selectedFilter) &&
        lesson.class.startsWith(selectedClass.replace("Kelas ", ""))
      );
    }
  });

  return (
    <div className="relative">
      <div className="flex flex-col items-center justify-center p-4 text-center text-[30px] font-bold text-[#2561BD]">
        Pelajaran SD Muhammadiyah Karangkajen
      </div>
      <div className="flex items-center justify-center gap-2">
        <button className="flex h-8 w-8 items-center justify-center rounded-full bg-[#4E94FF]">
          <FaAngleLeft size={25} className="text-white" />
        </button>
        <div>15 May 2024</div>
        <button className="flex h-8 w-8 items-center justify-center rounded-full bg-[#4E94FF]">
          <FaAngleRight size={25} className="text-white" />
        </button>
      </div>

      <div className="mt-5 flex justify-start pl-5">
        {classOptions.map((option) => (
          <button
            key={option}
            onClick={() => handleClassFilterChange(option)}
            className={`rounded-tl-lg rounded-tr-lg py-2 px-6 md:px-20 ${
              selectedClass === option
                ? "bg-blue-500 font-bold text-white md:text-[20px]"
                : "font-bold text-[#9DC4FF] md:text-[20px]"
            }`}
            // style={{
            //   clipPath: "polygon(10% 0, 90% 0, 100% 100%, 0 100%)",
            // }}
          >
            {option}
          </button>
        ))}
      </div>

      <div className="relative flex w-full flex-col overflow-visible rounded-2xl bg-gradient-to-r from-blue-500 to-blue-300 p-5">
        <FilterList
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
          handleFilter={handleFilterChange}
        />
        <div className="relative flex grid grid-cols-1 items-center justify-center gap-2 p-2 sm:grid-cols-2 md:grid-cols-3">
          {filteredLessons.map((lesson) => (
            <div
              key={lesson.id}
              className="relative rounded-lg bg-white p-5 shadow-md"
            >
              <h3 className="mb-2 text-xl font-bold text-[#2561BD]">
                {lesson.lessonName}
              </h3>
              <p className="text-[#2561BD]">{lesson.time}</p>
              <div className="flex items-center p-2">
                <img
                  src={lesson.teacherImage}
                  alt={lesson.teacher}
                  className="mr-3 h-6 w-6 rounded-full"
                />
                <p className="text-[#4E94FF]">{lesson.teacher}</p>
              </div>
              <div className="flex items-center p-3">
                <FaUserFriends className="mr-3 text-[#4E94FF]" />
                <p className="text-[#4E94FF]">{lesson.participants} Peserta</p>
              </div>
              <div className="absolute top-10 right-0 m-2 flex items-center justify-center px-2 py-1 text-[25px] font-bold text-[#2561BD]">
                {lesson.class}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Learn;
