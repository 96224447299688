import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const CkEditor = ({ isEditing, body, setBody }) => {
  return (
    <div className="md:w-2/3 md:pl-6">
      {isEditing ? (
        <>
          <label className="text-md mb-2 block font-bold font-medium text-gray-800 dark:text-white">
            Deskripsi
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={body}
            onChange={(event, editor) => {
              const data = editor.getData();
              setBody(data);
            }}
            config={{
              extraPlugins: [CustomUploadAdapterPlugin],
            }}
          />
        </>
      ) : (
        <div className="break-words dark:text-white">{parse(body)}</div>
      )}
    </div>
  );
};

export default CkEditor;
