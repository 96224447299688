import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

function EditMedsos({ id, onClose, medsosData }) {
  const [formData, setFormData] = useState({
    title: "",
    link: "",
  });
  const popupRef = useRef(null);

  useEffect(() => {
    const fetchMedsos = async () => {
      try {
        const response = await axios.get(`/api_s/medsos/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const medsosData = response.data.data;
        setFormData({
          title: medsosData.title,
          link: medsosData.link,
        });
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    if (id) {
      fetchMedsos();
    }

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id, onClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api_s/medsos/${id}`, formData, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Edit Media Sosial");
      medsosData();
      onClose();
    } catch (error) {
      toast.error(
        "Error Edit Media Sosial: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
      <form
        ref={popupRef}
        onSubmit={handleSubmit}
        className="w-full max-w-md rounded bg-white p-4 shadow-md"
      >
        <div className="mb-4">
          <label className="text-md text-grat-800 block font-bold font-medium">
            Link
          </label>
          <input
            type="url"
            name="link"
            value={formData.link}
            onChange={handleChange}
            className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            placeholder="https://example.com"
          ></input>
        </div>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="font bold rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
            onClick={onClose}
          >
            Batal
          </button>
          <button
            type="submit"
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditMedsos;
