import axios from "axios";

export default function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(loader);
  };
}

class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      this.loader.file.then((file) => {
        data.append("file", file);

        axios
          .post("/api_s/upload/single", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              db_name: process.env.REACT_APP_DB_NAME,
            },
          })
          .then((response) => {
            resolve({
              default: response.data.data.Location,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  abort() {}
}
