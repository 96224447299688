import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import logo from "../../../assets/img/logo.png";
import { FaCalendarMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";

function Pengumuman() {
  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get("/api_s/announcements", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const sortedAnnouncements = response.data.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 4);
        setAnnouncement(sortedAnnouncements);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchAnnouncements();
  }, []);

  const isImage = (fileName) => {
    if (!fileName) return false;
    const imageExtensions = ["jpeg", "jpg", "png", "webp", "svg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="mt-10 flex flex-col items-center">
      <div className="mb-4 text-3xl font-bold text-blue-700">Pengumuman</div>
      <div className="mt-4 grid w-full grid-cols-1 gap-6 px-4 sm:grid-cols-2 lg:grid-cols-4">
        {announcement.length > 0 ? (
          announcement.map((item) => (
            <div
              key={item.id}
              className="container rounded-lg bg-white shadow-md"
            >
              {isImage(item.file_url) ? (
                <div className="relative flex h-[250px] items-center justify-center overflow-hidden rounded-t-md">
                  <img
                    className="w-full transform object-contain transition-transform duration-300 hover:scale-110"
                    src={item.file_url}
                    alt={item.title}
                  />
                </div>
              ) : (
                <div className="relative flex h-[250px] items-center justify-center overflow-hidden rounded-t-md">
                  <img
                    className="w-full transform object-cover transition-transform duration-300 hover:scale-110"
                    src={logo}
                    alt="file"
                  />
                </div>
              )}
              <div className="ml-2 mt-2 flex items-center">
                <FaCalendarMinus className="mr-2 h-3.5 w-3.5 text-blue-600" />
                <span className="text-sm text-gray-400">
                  {formatDate(item.createdAt)}
                </span>
              </div>
              <Link to={`/pengumuman/${item.slug}`}>
                <div className="cursor-pointer p-2 text-xl font-bold text-blue-700 hover:text-blue-500">
                  {truncateText(item.title, 40)}
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center">
            <div className="mt-4 text-xl font-semibold text-gray-800">
              Tidak ada pengumuman saat ini.
            </div>
          </div>
        )}
      </div>
      {announcement.length > 0 && (
        <div className="mt-6 flex items-center justify-center">
          <Link
            to="/pengumuman"
            className="inline-flex items-center rounded-[10px] bg-blue-600 px-4 py-2 text-white shadow-lg transition duration-300 hover:bg-blue-500"
          >
            Lihat Selengkapnya
            <IoMdArrowForward className="ml-1 mt-1" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default Pengumuman;
