import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

const TablePendidik = () => {
  const [pendidik, setPendidik] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deletePendidikId, setDeletePendidikId] = useState(null);

  const fetchPendidik = async () => {
    try {
      const config = {
        method: "get",
        url: `/api_s/resources`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          db_name: process.env.REACT_APP_DB_NAME,
        },
      };
      const response = await axios.request(config);
      setPendidik(response.data.data); // Save received data to state
    } catch (error) {
      console.log(error.response);
      toast.error(
        "Error Mengambil Data Pendidik: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  useEffect(() => {
    fetchPendidik(); // Call on component mount to load initial data
  }, []);

  //hapus pendidik
  const handleDeleteClick = (id) => {
    setDeletePendidikId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/resources/${deletePendidikId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Pendidik");
      fetchPendidik();
    } catch (error) {
      toast.error("Gagal Hapus Pendidik");
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div className="">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Foto</th>
            <th className="px-6 py-3 text-center">Nama</th>
            <th className="px-6 py-3 text-center">Jabatan</th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {pendidik.length > 0 ? (
            pendidik.map((item, index) => (
              <tr key={item.id} className="border-b">
                <td className="px-6 py-4 text-center">{index + 1}</td>
                <td className="px-6 py-4 text-center">
                  <img
                    src={item.image_url}
                    alt="Foto Pendidik"
                    className="h-16 w-16 rounded-full object-cover"
                  />
                </td>
                <td className="px-6 py-4 text-center">{item.name}</td>
                <td className="px-6 py-4 text-center">{item.Position.name}</td>
                <td className="px-6 py-4 text-center">
                  <button className="mx-2 text-red-600 hover:text-red-800">
                    <FaTrash onClick={() => handleDeleteClick(item.id)} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="px-6 py-4 text-center">
                Tidak ada data pendidik
              </td>
            </tr>
          )}
          {showDeletePrompt && (
            <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
              <div className="shawdow-md rounded bg-white p-4">
                <h2 className="mb-4 text-lg font-semibold">
                  Konfirmasi Hapus Pendidik
                </h2>
                <p className="mb-4">
                  Apakah anda yakin akan menghapus pendidik ini?
                </p>
                <div className="flex justify-end gap-4">
                  <button
                    onClick={handleCancelDelete}
                    className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
                  >
                    Batal
                  </button>
                  <button
                    onClick={handleConfirmDelete}
                    className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TablePendidik;
