import React, { useEffect, useState } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "assets/img/logo.png";
import avatar from "assets/img/avatars/avatar4.png";
import axios from "axios";
import { toast } from "react-toastify";
import Login from "views/Login";
import Dropdown from "components/admin/dropdown";

const navigation = [
  { name: "Beranda", href: "/", current: false },
  { name: "Berita", href: "/berita", current: false },
  { name: "Agenda", href: "/agenda", current: false },
  { name: "Pengumuman", href: "/pengumuman", current: false },
  {
    name: "Profil",
    href: "",
    current: false,
    submenu: [
      { name: "Visi Misi", href: "/visi-misi" },
      { name: "Profil Sekolah", href: "/profile-sekolah" },
      { name: "Kontak", href: "/kontak" },
      { name: "Sambutan", href: "/sambutan" },
    ],
  },
  {
    name: "Galeri",
    href: "",
    current: false,
    submenu: [
      { name: "Foto", href: "/foto" },
      { name: "Video", href: "/video" },
    ],
  },
  { name: "PPDB", current: false },
];

// Helper function to concatenate class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [logos, setLogos] = useState([]);

  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));

  const toggleLoginPopup = () => {
    setIsLoginOpen(!isLoginOpen);
  };

  const closeLoginPopup = () => {
    setIsLoginOpen(false);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    closeLoginPopup();
  };

  //get users by id
  useEffect(() => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    if (token) {
      setIsLoggedIn(true);

      if (id) {
        const fetchUsersData = async () => {
          try {
            const response = await axios.get(`/api_s/users/${id}`, {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            });
            setUsers(response.data.data);
          } catch (error) {
            toast.error(error.response?.data?.message || error.message);
          }
        };

        fetchUsersData();
      }
    }
  }, [navigate]);

  //logout
  const handleLogout = async () => {
    const isConfirmed = window.confirm("Apakah Anda yakin ingin logout?");
    if (!isConfirmed) return;

    try {
      await axios.delete(`/api_s/users/logout`, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("role");
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      toast.error("Gagal Logout");
    }
  };

  //get logo by id 1
  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get("/api_s/logo", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const logoData = response.data.data || [];

        //Default logo
        const defaultLogos = [{ id: 1, url: logo }];

        //Check if the API response contains logo with id 1
        const logosDefaults = defaultLogos.map((defaultLogo) => {
          const dataLogo = logoData.find((logo) => logo.id === defaultLogo.id);
          return dataLogo || defaultLogo;
        });

        setLogos(logosDefaults);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setLogos([{ id: 1, url: logo }]);
      }
    };

    fetchLogoData();
  }, []);

  //cek format image profile pict
  const isValidImage = (url) => {
    const validExtensions = ["jpeg", "jpg", "png", "webp", "svg"];
    const extension = url.split(".").pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  const profileImage =
    users.profile_pict && isValidImage(users.profile_pict)
      ? users.profile_pict
      : avatar;

  return (
    <div>
      <Disclosure as="nav" className="fixed top-0 z-50 w-full bg-white">
        {({ open }) => (
          <div className="mx-auto mt-2 max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              {/* Logo & Nama Sekolah Tampilan Mobile */}
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <div className="ml-2 flex flex-1 justify-start sm:hidden">
                  {/* <Link to="/">
                    <img className="mt-2 h-10 w-auto" src={logo} alt="logo" />
                  </Link> */}
                  {logos.map((logo) => (
                    <Link to="/">
                      <img
                        className="mr-2 h-10 w-auto"
                        src={logo.url}
                        alt="logo"
                      />
                    </Link>
                  ))}
                  <Link to="/">
                    <span className="text-black ml-2 mt-4 text-lg font-bold">
                    SD Muhammadiyah Karangkajen
                    </span>
                  </Link>
                </div>
              </div>
              {/* Logo & Nama Sekolah Tampilan Desktop */}
              <div className="hidden flex-shrink-0 items-center sm:flex">
                {/* <Link to="/">
                  <img className="h-12 w-auto" src={logo} alt="logo" />
                </Link> */}
                {logos.map((logo) => (
                  <Link to="/">
                    <img className="h-12 w-auto" src={logo.url} alt="logo" />
                  </Link>
                ))}
                <Link to="/">
                  <span className="text-black ml-3 text-xl font-bold">
                  SD Muhammadiyah Karangkajen
                  </span>
                </Link>
              </div>
              {/* Menu Tampilan Desktop */}
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-center">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {updatedNavigation.map((item) => (
                      <div className="group relative" key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "text-black border-b-2 border-blue-400"
                              : "text-black hover:border-b-2 hover:border-blue-400",
                            "text-md flex items-center px-3 py-2 font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                          {item.submenu && (
                            <ChevronDownIcon className="ml-1 h-4 w-4 text-gray-800" />
                          )}
                        </Link>
                        {item.submenu && (
                          <div className="absolute hidden rounded-md bg-white shadow-lg group-hover:block">
                            {item.submenu.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={subItem.href}
                                className="text-black block rounded-md px-4 py-2 text-sm hover:bg-gray-200"
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* User Login Tampilan Desktop */}
                {isLoggedIn ? (
                  <Dropdown
                    button={
                      <img
                        className="hidden h-10 w-10 rounded-full md:block"
                        src={profileImage}
                        alt={users.name}
                      />
                    }
                    children={
                      <div className="flex hidden w-36 flex-col justify-start rounded-[10px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 md:block">
                        <div className="p-4">
                          <div className="flex items-center gap-2">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              👋 Hey, {users.name}
                            </p>
                          </div>
                        </div>
                        <div className="h-px w-full bg-gray-200" />

                        <div className="flex flex-col p-4">
                          <div
                            onClick={handleLogout}
                            className="cursor-pointer text-sm text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in"
                          >
                            Logout
                          </div>
                        </div>
                      </div>
                    }
                    classNames={"py-2 top-8 -left-[120px] w-max"}
                  />
                ) : (
                  <button
                    onClick={toggleLoginPopup}
                    type="button"
                    className="relative flex hidden items-center justify-center rounded-full border-2 border-[#2561BD] p-2 px-4 py-1 text-[#2561BD] hover:border-[#2561BD] hover:bg-[#2561BD] hover:text-white md:block"
                  >
                    Login
                  </button>
                )}
                {/* Icon Hamburger Tampilan Mobile */}
                <DisclosureButton className="text-black relative inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white sm:hidden">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
            </div>
            {/* Menu Tampilan Mobile */}
            <DisclosurePanel className="absolute inset-x-0 top-16 z-50 bg-white sm:hidden">
              <div className="flex flex-col space-y-1">
                {updatedNavigation.map((item) => (
                  <div className="group relative" key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "text-black border-b-2 border-blue-400"
                          : "text-black hover:border-b-2 hover:border-blue-400",
                        "text-md flex items-center px-3 py-2 font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                      {item.submenu && (
                        <ChevronDownIcon className="ml-1 h-4 w-4 text-gray-500" />
                      )}
                    </Link>
                    {item.submenu && (
                      <div className="absolute left-20 top-full mt-2 hidden rounded-md bg-white shadow-lg group-hover:block">
                        {item.submenu.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.href}
                            className="text-black block rounded-md px-4 py-2 text-sm hover:bg-gray-200"
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {/* User Login Tampilan Mobile */}
              <div className="p-2">
                {isLoggedIn ? (
                  <Dropdown
                    button={
                      <img
                        className="h-10 w-10 rounded-full sm:hidden"
                        src={profileImage}
                        alt={users.name}
                      />
                    }
                    children={
                      <div className="flex w-[220px] justify-end rounded-[8px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 sm:hidden">
                        <div className="flex flex-col p-2">
                          <div className="text-sm font-bold text-navy-700">
                            👋 Hey, {users.name}
                          </div>
                          <div
                            onClick={handleLogout}
                            className="mt-3 cursor-pointer text-sm text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in"
                          >
                            Logout
                          </div>
                        </div>
                      </div>
                    }
                    classNames={"py-4 top-5 -left-[85px] w-max"}
                  />
                ) : (
                  <button
                    onClick={toggleLoginPopup}
                    type="button"
                    className="relative flex items-center justify-center rounded-full border-2 border-[#2561BD] p-2 px-4 py-1 text-[#2561BD] hover:border-[#2561BD] hover:bg-[#2561BD] hover:text-white sm:hidden"
                  >
                    Login
                  </button>
                )}
              </div>
            </DisclosurePanel>
          </div>
        )}
      </Disclosure>
      {/* Popup Login */}
      {isLoginOpen && (
        <Login
          closePopup={closeLoginPopup}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
    </div>
  );
}
