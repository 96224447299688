import React from "react";

const Dashboard = () => {
  return (
    <div className="mt-5">
      <div className="flex items-center justify-center text-[30px] font-bold dark:text-white">
        Selamat Datang di Dashboard Admin
      </div>
    </div>
  );
};

export default Dashboard;
