import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};

const defaultCenter = {
  lat: -7.8905, // default latitude
  lng: 110.3264, // default longitude
};

const Maps = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD0EuEBPjS92sMND4h2cp_j5mGPFzjdtpE", // secure your API key
    libraries,
  });

  const [schoolData, setSchoolData] = useState({
    school_name: "",
    address: "",
  });

  const [schoolContact, setSchoolContact] = useState({
    link_maps: "",
    coordinates: "",
  });

  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    const fetchDataSekolah = async () => {
      try {
        const response = await axios.get(`/api_s/schoolprofiles`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const data = response.data.data[0];
        setSchoolData(data);
      } catch (error) {
        console.log(error.response);
      }
    };
    fetchDataSekolah();
  }, []);

  useEffect(() => {
    const fetchKontakSekolah = async () => {
      try {
        const response = await axios.get(`/api_s/contacts`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const contact = response.data.data[0];
        setSchoolContact(contact || {});
      } catch (error) {
        console.log(error.response);
      }
    };
    fetchKontakSekolah();
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  let coordinates = defaultCenter;
  if (schoolContact?.coordinates) {
    const [lat, lng] = schoolContact.coordinates.split(",").map(Number);
    coordinates = { lat, lng };
  }

  const handleMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={coordinates}
        options={{
          zoomControl: true,
        }}
      >
        <Marker position={coordinates} onClick={handleMarkerClick} />
        {showInfoWindow && (
          <InfoWindow position={coordinates} onClick={handleInfoWindowClose}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "justify",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                }}
              >
                <div
                  className="text-left font-bold"
                  style={{ marginRight: "auto" }}
                >
                  {schoolData.school_name || "SD Muhammadiyah Karangkajen"}
                </div>
              </div>
              <br />
              <span
                className="w-40"
                style={{ display: "inline-block", textOverflow: "ellipsis" }}
              >
                {schoolData.address ||
                  "Jl. Gajah Mada No.7B, Kurahan, Bantul, Kec. Bantul"}
              </span>
              <br />
              <a
                href={
                  schoolContact.link_maps ||
                  "https://maps.app.goo.gl/mffxZ3Sn14mYZu6j9"
                }
                className="text-blue-700"
              >
                View on Google Maps
              </a>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default Maps;
