import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";

const TableUser = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const role = localStorage.getItem("role");
  const usersPerPage = 50;

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(`/api_s/users`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setUsers(response.data.data);
        // console.log(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchUsersData();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-user/${id}`);
  };

  const pageCount = Math.ceil(users.length / usersPerPage);
  const offset = currentPage * usersPerPage;
  const currentUsers = users.slice(offset, offset + usersPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Foto</th>
            <th className="px-6 py-3 text-center">Name</th>
            <th className="px-6 py-3 text-center">Username / Email</th>
            <th className="px-6 py-3 text-center">Phone</th>
            <th className="px-6 py-3 text-center">Role</th>
            {role === "superadmin" && (
              <th className="px-6 py-3 text-center">Aksi</th>
            )}
          </tr>
        </thead>
        <tbody className="text-sm">
          {currentUsers.map((item, index) => (
            <tr
              key={item.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="px-6 py-3 text-center">{offset + index + 1}</td>
              <td className="px-6 py-3 text-center">
                <img
                  alt=""
                  src={item.profile_pict}
                  className="h-10 w-10 text-center"
                />
              </td>
              <td className="px-6 py-3 text-center">{item.name}</td>
              <td className="px-6 py-3 text-center">
                {item.username} / {item.email}
              </td>
              <td className="px-6 py-3 text-center">{item.phone || "-"}</td>
              <td className="px-6 py-3 text-center">{item.role}</td>
              {role === "superadmin" && (
                <td className="px-6 py-3 text-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        {pageCount > 1 && (
          <ReactPaginate
            containerClassName={"pagination flex space-x-2"}
            pageClassName={"page-item flex items-center justify-center h-8 w-8"}
            activeClassName={"active bg-blue-500 text-white rounded-full"}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            breakLabel="..."
            previousLabel={
              <FaCircleChevronLeft
                className={`h-8 w-8 ${
                  currentPage === 0
                    ? "cursor-not-allowed text-gray-300"
                    : "cursor-pointer text-gray-400"
                }`}
              />
            }
            nextLabel={
              <FaCircleChevronRight
                className={`h-8 w-8 ${
                  currentPage === pageCount - 1
                    ? "cursor-not-allowed text-gray-300"
                    : "cursor-pointer text-gray-400"
                }`}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
export default TableUser;
