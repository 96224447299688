import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Presensi from "components/user/home/Presensi";
// import Sejarah from "components/user/home/Sejarah";
import Sosmed from "components/user/home/Sosmed";
import Visi from "components/user/home/Visi";
import Learn from "components/user/home/Learn";
import Evaluation from "components/user/home/Evaluation";
import FolderDrive from "components/user/home/FolderDrive";
import Testimoni from "components/user/home/Testimoni";
import GaleriFoto from "components/user/home/GaleriFoto";
import GaleriVideo from "components/user/home/GaleriVideo";
import Siswa1 from "../../../assets/img/kemah.png";
import Siswa2 from "../../../assets/img/pengajian.png";
import Siswa3 from "../../../assets/img/musa.png";
import Siswa4 from "../../../assets/img/perjusa.png";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import BeritaTerbaru from "components/user/home/BeritaTerbaru";
import Agenda from "components/user/home/Agenda";
import Pengumuman from "components/user/home/Pengumuman";
import Pendidik from "components/user/home/Pendidik";

function About() {
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);

  const defaultPhotos = [
    {
      url: Siswa1,
      createdAt: "2024-08-21T00:00:00Z",
      title: "SD Muhammadiyah Karangkajen melaksanakan kemah pada hari pramuka",
    },
    {
      url: Siswa2,
      createdAt: "2024-08-21T00:00:00Z",
      title: "SD Muhammadiyah Karangkajen melakukan kegiatan pengajian rutinan",
    },
    {
      url: Siswa3,
      createdAt: "2024-08-21T00:00:00Z",
      title: "Upacara Bendera SD Muhammadiyah Karangkajen",
    },
    {
      url: Siswa4,
      createdAt: "2024-08-21T00:00:00Z",
      title: "Penyerahan Medali pada lomba Perjusami SD Muhammadiyah Karangkajen",
    },
  ];

  const defaultVideos = [
    {
      url: "https://youtu.be/4Okjdo481Yk?si=peZ7Ll-j9kl-Fwul",
      createdAt: "2024-08-21T00:00:00Z",
      title: "Sambutan Kepala SD Muhammadiyah Karangkajen",
    },
    {
      url: "https://youtu.be/Qs_A8qx-RaU?si=Q3-tkfozoqbxpWel",
      createdAt: "2024-08-21T00:00:00Z",
      title: "Kemah Hari Pramuka SD Muhammadiyah Karangkajen",
    },
  ];

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api_s/galeries?type=video`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Jika data dari API kosong, gunakan data default
        if (response.data.data && response.data.data.length > 0) {
          setVideos(response.data.data);
        } else {
          setVideos(defaultVideos);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setVideos(defaultVideos);
      }
    };

    fetchVideoData();
  }, []);

  useEffect(() => {
    const fetchPhotoData = async () => {
      try {
        const response = await axios.get("/api_s/galeries?type=image", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Jika data dari API kosong, gunakan data default
        if (response.data.data && response.data.data.length > 0) {
          setPhotos(response.data.data);
        } else {
          setPhotos(defaultPhotos);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setPhotos(defaultPhotos);
      }
    };

    fetchPhotoData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      {/* Desktop Devices */}
      {/* <div className="mt-10 hidden p-10 md:block">
        <Sejarah />
      </div> */}
      <div className="hidden p-4 md:block">
        <BeritaTerbaru />
      </div>
      <div className="mt-10 hidden md:block">
        <Visi />
      </div>
      <div className="mt-10">
        <Sosmed />
      </div>
      <div className="mt-10">
        <GaleriFoto photos={photos} />
      </div>
      <div className="mt-10 flex flex-col items-center">
        <div className="mb- mb-4 text-3xl font-bold text-blue-700">
          Galeri Video
        </div>
        <div className="flex flex-wrap justify-center">
          {videos.slice(0, 3).map((video, index) => (
            <GaleriVideo
              key={index}
              videoUrl={video.url}
              createdAt={formatDate(video.createdAt)}
              title={truncateText(video.title, 45)}
            />
          ))}
        </div>
        <div className="mt-4 flex items-center justify-center">
          <Link
            to={"/video"}
            className="inline-flex items-center rounded-[10px] bg-blue-600 px-4 py-2 text-white shadow-lg transition duration-300 hover:bg-blue-700"
          >
            Lihat Selengkapnya
            <IoMdArrowForward className="ml-1 mt-1" />
          </Link>
        </div>
      </div>
      <div className="mt-10">
        <Agenda />
      </div>
      <div className="mt-10">
        <Pengumuman />
      </div>
      <div className="mt-10">
        <Pendidik />
      </div>
      <div className="mt-10">
        <Presensi />
      </div>
      <div className="mt-10">
        <Learn />
      </div>
      <div className="mt-10">
        <Evaluation />
      </div>
      <div className="mt-10">
        <FolderDrive />
      </div>
      <div className="mt-10">
        <Testimoni />
      </div>
    </>
  );
}

export default About;
