import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const CkEditor = ({ data, onChange, update }) => {
  return (
    <div className="mb-4">
      <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
        Sekilas Profile
      </label>
      {update ? (
        <CKEditor
          editor={ClassicEditor}
          data={data}
          onChange={onChange}
          config={{
            extraPlugins: [CustomUploadAdapterPlugin],
          }}
        />
      ) : (
        <div className="block w-full rounded-md border-2 bg-gray-100 px-3 py-2 shadow-sm outline-none">
          {parse(data)}
        </div>
      )}
    </div>
  );
};

export default CkEditor;
