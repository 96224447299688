import React from "react";
import { useNavigate } from "react-router-dom";
import TablePengumuman from "./components/TablePengumuman";

function Pengumuman() {
  const navigate = useNavigate();

  const handleTambahClick = () => {
    navigate("/admin/add-pengumuman");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-end">
        <div>
          <button
            className="mr-2 rounded bg-blue-500 px-4 py-2 text-white"
            onClick={handleTambahClick}
          >
            Tambah Pengumuman
          </button>
        </div>
      </div>
      <TablePengumuman />
    </div>
  );
}

export default Pengumuman;
