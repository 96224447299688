import React, { useEffect, useState } from "react";
import Fb from "assets/img/icon-sosmed/fb.png";
import Ig from "assets/img/icon-sosmed/ig.png";
import x from "assets/img/icon-sosmed/x.png";
import Whatsapp from "assets/img/icon-sosmed/wa.png";
import yt from "assets/img/icon-sosmed/yt.png";
import tiktok from "assets/img/icon-sosmed/tiktok.png";
import telegram from "assets/img/icon-sosmed/telegram.png";
import linkedin from "assets/img/icon-sosmed/linkedIn.png";
import line from "assets/img/icon-sosmed/line.png";
import messenger from "assets/img/icon-sosmed/messenger.png";
import axios from "axios";
import { toast } from "react-toastify";

function Sosmed() {
  const [medsos, setMedsos] = useState([]);
  const socialMediaIcons = [
    { name: "Whatsapp", icon: Whatsapp },
    { name: "Facebook", icon: Fb },
    { name: "Instagram", icon: Ig },
    { name: "X (Twitter)", icon: x },
    { name: "Youtube", icon: yt },
    { name: "Tiktok", icon: tiktok },
    { name: "Telegram", icon: telegram },
    { name: "LinkedIn", icon: linkedin },
    { name: "Line", icon: line },
    { name: "Messenger", icon: messenger },
  ];

  useEffect(() => {
    const fetchKontak = async () => {
      try {
        const response = await axios.get(`/api_s/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setMedsos(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchKontak();
  }, []);

  return (
    <div className="relative flex items-center justify-center bg-blue-100">
      <div className="mx-auto">
        <p className="tracking-0 p-6 text-center text-2xl font-semibold italic leading-normal text-[#2561bd] md:text-3xl">
          Ikuti SD Muhammadiyah Karangkajen di Media Sosial
        </p>
        <div className="flex flex-wrap justify-center gap-8 p-2 md:gap-20 md:p-4">
          <div className="flex flex-row items-center justify-center gap-2">
            {socialMediaIcons
              .filter((icon) => medsos.some((i) => i.title === icon.name))
              .map((icon) => {
                const socialMediaData = medsos.find(
                  (i) => i.title === icon.name
                );
                const socialMediaLink = socialMediaData?.link;
                const socialMediaTitle = socialMediaData?.title || icon.name;

                return (
                  <a
                    key={icon.name}
                    href={socialMediaLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-12 w-12 md:h-16 md:w-16"
                      src={icon.icon}
                      alt={icon.name}
                    />
                    <div className="text-center text-[16px] leading-[normal] tracking-[0] text-[#2561bd]">
                      {socialMediaTitle}
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sosmed;
