import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import axios from "axios";
import { toast } from "react-toastify";
import parse from "html-react-parser";

const RunningText = ({ texts }) => {
  return (
    <div className="running-text mt-[65px] flex h-[45px] items-center justify-center overflow-hidden bg-[#2561BD] text-center md:mt-20">
      <marquee
        behavior="scroll"
        direction="left"
        scrollamount="6"
        className="w-full"
      >
        {texts.length > 0 ? (
          texts.map((text, index) => (
            <span key={index} className="text-white">
              {text.body
                ? parse(text.body)
                : "Selamat datang di SD Muhammadiyah Karangkajen"}
            </span>
          ))
        ) : (
          <span className="text-white">
            Selamat datang di SD Muhammadiyah Karangkajen
          </span>
        )}
      </marquee>
    </div>
  );
};

export default function CarouselHome() {
  const [runningTexts, setRunningTexts] = useState([]);
  const [carousels, setCarousels] = useState([]);

  useEffect(() => {
    const fetchRunningTexts = async () => {
      try {
        const response = await axios.get(`/api_s/runningtexts?status=1`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setRunningTexts(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchRunningTexts();
  }, []);

  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await axios.get(`/api_s/carousels`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setCarousels(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchCarousels();
  }, []);

  return (
    <>
      <RunningText texts={runningTexts} />
      <Swiper
        style={{
          "--swiper-pagination-color": "#fff",
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="swiper-carousel"
      >
        {carousels.map((item) => (
          <SwiperSlide key={item.id}>
            <img
              className="h-[400px] w-[100%] md:h-[800px] md:object-cover"
              src={item.image_url}
              alt={item.title}
              loading="lazy"
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
