import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const EditText = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const { id } = useParams();

  const handleCancel = () => {
    navigate("/admin/text");
  };

  useEffect(() => {
    const fetchRunningTextData = async () => {
      try {
        const response = await axios.get(`/api_s/runningtexts/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const text = response.data.data;
        setBody(text.body);
        setTitle(text.title);
        setStatus(text.status);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchRunningTextData();
  }, [id]);

  const checkActiveText = async () => {
    try {
      const response = await axios.get(`/api_s/runningtexts?status=1`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      return response.data.data.length > 0;
    } catch (error) {
      toast.error("Error checking active texts.");
      return false;
    }
  };

  const handleSave = async () => {
    if (status === "true") {
      const hasActiveText = await checkActiveText();
      if (hasActiveText) {
        toast.error(
          "Sudah ada text berjalan dengan status aktif. Tidak bisa mengubah status menjadi aktif."
        );
        return;
      }
    }
    try {
      const response = await axios.put(
        `/api_s/runningtexts/${id}`,
        {
          title,
          body,
          status,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data);
      toast.success("Berhasil Edit Text Berjalan!");
      navigate("/admin/text");
    } catch (error) {
      toast.error(
        "Error edit text berjalan: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [CustomUploadAdapterPlugin],
        }}
        data={body}
        onChange={(event, editor) => {
          const data = editor.getData();
          setBody(data);
        }}
      />
      <div className="mb-4 mt-4">
        <label className="text-md mb-2 block font-bold font-bold text-gray-800 dark:text-white">
          Status
        </label>
        <select
          id="status"
          name="status"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="">Pilih Status</option>
          <option value="true">active</option>
          <option value="false">non active</option>
        </select>
      </div>
      <div className="my-5 flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={handleSave}
        >
          Edit
        </button>
      </div>
    </div>
  );
};
export default EditText;
