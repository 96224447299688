import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiSortAlt2 } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";

const TablePosts = ({ selectedCategory }) => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deletePostsId, setDeletePostsId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const postsPerPage = 50;

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_s/posts`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setPosts(response.data.data);
        // console.log(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const handleEditClick = (id) => {
    navigate(`/admin/edit-posts/${id}`);
  };

  const handleDeleteClick = (id) => {
    setDeletePostsId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/posts/${deletePostsId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Postingan!");
      const response = await axios.get(`/api_s/posts`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setPosts(response.data.data);
    } catch (error) {
      toast.error(
        "Error Hapus Postingan" +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const handleSortByDate = () => {
    const sortedPosts = [...posts].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setPosts(sortedPosts);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredPosts = selectedCategory
    ? posts.filter((post) => post.category_id === parseInt(selectedCategory))
    : posts;

  const pageCount = Math.ceil(filteredPosts.length / postsPerPage);
  const offset = currentPage * postsPerPage;
  const currentPosts = filteredPosts.slice(offset, offset + postsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Gambar</th>
            <th className="px-6 py-3 text-center">Judul</th>
            <th className="px-6 py-3 text-center">Kategori</th>
            <th className="px-6 py-3 text-center">Author</th>
            <th className="px-6 py-3 text-center">
              <button
                onClick={handleSortByDate}
                className="flex items-center justify-center"
              >
                Tanggal
                <BiSortAlt2 className="ml-1" />
              </button>
            </th>
            <th className="px-6 py-3 text-center">Status</th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm ">
          {currentPosts.map((item, index) => (
            <tr
              key={item.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="px-6 py-3 text-center">{offset + index + 1}</td>
              <td className="px-6 py-3 text-left">
                <img src={item.image} alt={item.title} className="h-10 w-10" />
              </td>
              <td className="px-6 py-3 text-left">{item.title}</td>
              <td className="px-6 py-3 text-left">{item.PostCategory.name}</td>
              <td className="px-6 py-3 text-left">{item.User.name}</td>
              <td className="px-6 py-3 text-center">{formatDate(item.date)}</td>
              <td className="px-6 py-3 text-center">
                <button
                  className={`rounded-full px-2 ${
                    item.status
                      ? "bg-green-100 text-green-600"
                      : "whitespace-nowrap bg-red-100 text-red-500"
                  }`}
                >
                  {item.status ? "active" : "non active"}
                </button>
              </td>

              <td className="px-6 py-3 text-center">
                <div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 transform text-red-500 hover:scale-110 hover:text-red-600"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Postingan
            </h2>
            <p className="mb-4">
              Apakah anda yakin akan menghapus postingan ini?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        {pageCount > 1 && (
          <ReactPaginate
            containerClassName={"pagination flex space-x-2"}
            pageClassName={"page-item flex items-center justify-center h-8 w-8"}
            activeClassName={"active bg-blue-500 text-white rounded-full"}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            breakLabel="..."
            previousLabel={
              <FaCircleChevronLeft
                className={`h-8 w-8 ${
                  currentPage === 0
                    ? "cursor-not-allowed text-gray-300"
                    : "cursor-pointer text-gray-400"
                }`}
              />
            }
            nextLabel={
              <FaCircleChevronRight
                className={`h-8 w-8 ${
                  currentPage === pageCount - 1
                    ? "cursor-not-allowed text-gray-300"
                    : "cursor-pointer text-gray-400"
                }`}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default TablePosts;
