import React from "react";
import { useNavigate } from "react-router-dom";
import TableHeader from "./components/TableHeader";

function Header() {
  const navigate = useNavigate();
  const handleTambahHeader = () => {
    navigate("/admin/add-header");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <button
            onClick={handleTambahHeader}
            className="mr-2 rounded bg-blue-500 px-4 py-2 text-white"
          >
            Tambah Header
          </button>
        </div>
      </div>
      <TableHeader></TableHeader>
    </div>
  );
}; export default Header;
