import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

const TableHeader = () => {
  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteHeadersId, setDeleteHeadersId] = useState(null);

  useEffect(() => {
    const fetchGaleriesData = async () => {
      try {
        const response = await axios.get(`/api_s/header`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setHeaders(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.log(error.response);
        toast.error("Error fetching data", error);
      }
    };

    fetchGaleriesData();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-header/${id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteHeadersId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/header/${deleteHeadersId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Header");
      const response = await axios.get(`/api_s/header`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setHeaders(response.data.data);
    } catch (error) {
      toast.error(
        "Gagal Hapus Header" + (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div className="">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Judul</th>
            <th className="px-6 py-3 text-center">Gambar</th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {headers.map((item, index) => (
            <tr className="border-b border-gray-200 hover:bg-gray-100">
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="px-6 py-3 text-center">{item.title}</td>
              <td className="px-6 py-3 text-center">
                <div className="flex items-center justify-center">
                  <img
                    src={item.image_url}
                    alt={item.title}
                    className="h-20 w-20"
                  />
                </div>
              </td>

              <td className="px-6 py-3 text-center">
                <div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 transform text-red-500 hover:scale-110 hover:text-red-600"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Headers
            </h2>
            <p className="mb-4">
              Apakah anda yakin akan menghapus headers ini?
            </p>
            <div className="flex justify-end gap-4">
              <button
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
                onClick={handleCancelDelete}
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TableHeader;
