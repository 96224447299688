import axios from "axios";
import Header from "components/user/Header";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import banner from "assets/img/banner.png";
import { Gallery } from "react-grid-gallery";
import Footer from "components/user/Footer";

function Foto() {
  const [images, setImages] = useState([]);
  const [headerImage, setHeaderImage] = useState(""); // State untuk menyimpan URL Banner

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`/api_s/galeries?type=image`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const formattedImages = response.data.data.map((image) => ({
          src: image.url,
          thumbnail: image.url,
          thumbnailCaption: (
            <div className="flex items-center justify-center text-center">
              {image.title}
            </div>
          ),
          caption: image.title,
        }));
        setImages(formattedImages);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    const fetchHeader = async () => {
      try {
        const response = await axios.get(`/api_s/header?title=galeri`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        // Check if response contains the expected structure
        if (response.data && response.data.data) {
          const imageData = response.data.data;

          // Assuming imageData could be an array of objects, extract the URL properly
          if (Array.isArray(imageData)) {
            // Adjust this logic based on your actual data structure
            setHeaderImage(imageData[2]?.image_url || ""); // Set to an empty string if not found
          } else if (typeof imageData === "string") {
            setHeaderImage(imageData);
          } else {
            toast.error("Invalid image data format.");
          }
        } else {
          toast.error("No data found in the response.");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchImages();
    fetchHeader();
  }, []);

  return (
    <>
      <Header />
      <div className="relative top-[35px] mt-[30px] inline-flex h-auto w-full">
        {headerImage ? ( // Render the image only if headerImage is available
          <img
            className="banner h-auto w-full"
            src={headerImage}
            alt="banner"
          />
        ) : (
          // Optionally, you can display a message or an empty div if no header image is available
          <div className="banner-placeholder flex h-auto w-full items-center justify-center bg-gray-200">
            <p className="text-gray-600">No Header Image Available</p>
          </div>
        )}
        <div className="max-w-500 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center font-bold text-white">
          <p className="text-xl md:text-4xl">
            Galeri <br /> SD Muhammadiyah Karangkajen
          </p>
        </div>
      </div>
      <div className="mx-auto mb-5 mt-[50px] px-4">
        <Gallery images={images} enableImageSelection={false} />
      </div>
      <Footer />
    </>
  );
}

export default Foto;
