import React from "react";
import comingsoon from "../../assets/img/coming-soon.png";

function ComingSoon() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center">
        <img
          className="mx-auto mb-2 h-[390px]"
          src={comingsoon}
          alt="coming-soon"
        />
        <h1 className="mb-2 text-3xl font-bold dark:text-white">
          Halaman masih dalam tahap pengembangan. <br /> Stay tuned!
        </h1>
      </div>
    </div>
  );
}

export default ComingSoon;
