import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Footer from "components/user/Footer";
import Header from "components/user/Header";
import Welcome from "components/user/home/Welcome";
import CarouselHome from "components/user/home/Carousel";
import About from "./About";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { toast } from "react-toastify";

const PopupCarousel = ({ popups, onClose }) => {
  const popupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div ref={popupRef} className="bg-transparant">
        {popups.map((popup) => (
          <a href={popup.link} target="_blank" rel="noopener noreferrer">
            <div className="flex flex-col items-center justify-center">
              <img
                src={popup.image_url}
                alt={popup.title}
                className="h-[475px] w-auto object-cover"
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

function Home() {
  const [popups, setPopups] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const fetchPopupsData = async () => {
      try {
        const response = await axios.get(`/api_s/popups?status=1`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setPopups(response.data.data);

        if (response.data.data.length > 0) {
          setShowPopup(true);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPopupsData();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 0 && !showArrow) {
      setShowArrow(true);
    } else if (scrollTop === 0 && showArrow) {
      setShowArrow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showArrow]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <CarouselHome />
      <Welcome />
      <About />
      <div className="mt-10">
        <Footer />
      </div>
      {showPopup && popups.length > 0 && (
        <PopupCarousel popups={popups} onClose={handleClosePopup} />
      )}
      {showArrow && (
        <div
          className="fixed bottom-10 right-10 cursor-pointer"
          onClick={scrollToTop}
        >
          <IoIosArrowDropupCircle className="h-8 w-8 text-blue-500" />
        </div>
      )}
    </>
  );
}

export default Home;
