import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ImageSection from "./components/ImageSection";
import CkEditor from "./components/CkEditor";
import ButtonSambutan from "./components/Buttons";
import CustomUploadAdapterPlugin from "components/admin/ckeditor/UploadAdapter";

const Sambutan = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  useEffect(() => {
    fetchDataSambutan();
  }, []);

  const fetchDataSambutan = async () => {
    try {
      const response = await axios.get(`/api_s/abouts?title=Sambutan`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      const sambutanData = response.data.data;

      if (sambutanData.length > 0) {
        const sambutan = sambutanData[0];
        setId(sambutan.id);
        setTitle(sambutan.title);
        setBody(sambutan.body);
        setImageUrl(sambutan.image_url);
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }

      setHasFetchedData(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/api_s/upload/single`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });

        const uploadedImageUrl = response.data.data.Location;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Error Upload Gambar");
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `/api_s/abouts/${id}`,
        {
          id,
          title,
          body,
          image_url: imageUrl,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );

      console.log(response.data.data);
      toast.success("Berhasil Edit Sambutan!");
      setIsEditing(false);
      fetchDataSambutan();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const handleCancel = () => {
    fetchDataSambutan();
    setIsEditing(false);
  };

  if (!hasFetchedData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative p-5">
      {isEditing ? (
        <div className="mt-10 flex flex-col p-5 md:flex-row">
          <ImageSection
            isEditing={isEditing}
            imageUrl={imageUrl}
            title={title}
            handleFileChange={handleFileChange}
          />
          <CkEditor
            isEditing={isEditing}
            body={body}
            setBody={setBody}
            config={{
              extraPlugins: [CustomUploadAdapterPlugin],
            }}
          />
        </div>
      ) : (
        <>
          <button
            onClick={() => setIsEditing(true)}
            className="absolute right-0 top-0 m-4 flex rounded-md bg-blue-500 px-3 py-2 text-white"
          >
            Edit
          </button>
          <div className="mt-10 flex flex-col p-5 md:flex-row">
            <ImageSection
              isEditing={isEditing}
              imageUrl={imageUrl}
              title={title}
              handleFileChange={handleFileChange}
            />
            <CkEditor
              isEditing={isEditing}
              body={body}
              setBody={setBody}
              config={{
                extraPlugins: [CustomUploadAdapterPlugin],
              }}
            />
          </div>
        </>
      )}
      <ButtonSambutan
        isEditing={isEditing}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        id={id}
      />
    </div>
  );
};

export default Sambutan;
