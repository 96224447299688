import React from "react";
import { useNavigate } from "react-router-dom";
import TableMedsos from "./components/TableMedsos";

function Medsos() {
  const navigate = useNavigate();

  const handleTambahClick = () => {
    navigate("/admin/add-medsos");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-end">
        <div>
          <button
            className="mr-2 rounded bg-blue-500 px-4 py-2 text-white"
            onClick={handleTambahClick}
          >
            Tambah Media Sosial
          </button>
        </div>
      </div>
      <TableMedsos />
    </div>
  );
}

export default Medsos;
