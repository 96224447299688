import React from "react";
import { useNavigate } from "react-router-dom";
import TableMediaFoto from "./components/TableMediaFoto";

function Foto() {
  const navigate = useNavigate();

  const handleLihatKategori = () => {
    navigate("/admin/kategorifoto");
  };

  const handleTambahFoto = () => {
    navigate("/admin/add-photo");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <button onClick={handleTambahFoto} className="mr-2 rounded bg-blue-500 px-4 py-2 text-white">
            Tambah Foto
          </button>
          <button
            onClick={handleLihatKategori}
            className="mr-2 rounded bg-green-500 px-4 py-2 text-white"
          >
            Lihat Kategori
          </button>
        </div>
      </div>
      <TableMediaFoto></TableMediaFoto>
    </div>
  );
}
export default Foto;
