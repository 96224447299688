import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";

const Jabatan = () => {
  const [positions, setPositions] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deletePositionId, setDeletePositionId] = useState(null);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeleteClick = (id) => {
    setDeletePositionId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `/api_s/positions/${deletePositionId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      toast.success("Berhasil Hapus Jabatan");
      fetchPositions();
    } catch (error) {
      toast.error("Gagal Hapus Jabatan");
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        ...formData,
      };

      const config = {
        method: "post",
        url: `/api_s/positions`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      toast.success("Berhasil Tambah Jabatan!");

      // Option 1: Fetch all positions again after adding a new position
      fetchPositions();

      // Option 2: Add the new position to the state directly
      // Uncomment the f ollowing line if you prefer to update the list without re-fetching all positions
      // setPositions((prevPositions) => [...prevPositions, response.data.data]);

      // Clear form data after submission
      setFormData({ type: "", name: "" });
    } catch (error) {
      console.log(error.response);
      toast.error(
        "Error Tambah Jabatan: " +
        (error.response?.data?.message || error.message)
      );
    }
  };

  const fetchPositions = async () => {
    try {
      const config = {
        method: "get",
        url: `/api_s/positions`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          db_name: process.env.REACT_APP_DB_NAME,
        },
      };
      const response = await axios.request(config);
      setPositions(response.data.data); // Save received data to state
    } catch (error) {
      console.log(error.response);
      toast.error(
        "Error Mengambil Data Jabatan: " +
        (error.response?.data?.message || error.message)
      );
    }
  };

  useEffect(() => {
    fetchPositions(); // Call on component mount to load initial data
  }, []);

  return (
    <div className="w-full">
      <div className="card relative mx-auto rounded-lg bg-gray-200 p-4 shadow-md">
        <form
          className="flex w-full flex-wrap items-center justify-between"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-1">
            <div className="mx-4 mb-4 w-full">
              <label
                htmlFor="type"
                className="block text-left text-xl font-medium text-gray-700"
              >
                Type
              </label>
              <select
                id="type"
                name="type"
                className="form-control w-full"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Pilih Type Pendidik</option>
                <option value="teacher">Guru</option>
                <option value="employee">Karyawan</option>
              </select>
            </div>
          </div>

          <div className="flex flex-1">
            <div className="mx-4 mb-4 w-full">
              <label
                htmlFor="name"
                className="block text-left text-xl font-medium text-gray-700"
              >
                Jabatan
              </label>
              <input
                id="jabatan"
                name="name"
                type="text"
                className="form-control w-full"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="ml-4 mt-4 flex items-center">
            <button
              type="submit"
              className="border-transparent inline-flex items-center rounded-md border bg-blue-500 px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-blue-600 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-200 active:bg-blue-700"
            >
              Tambah
            </button>
          </div>
        </form>
      </div>

      <table className="mt-6 w-full bg-white shadow-md">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Tipe</th>
            <th className="px-6 py-3 text-center">Jabatan</th>
            <th className="px-6 py-3 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {positions.length > 0 ? (
            positions.map((position, index) => (
              <tr key={position.id} className="border-b">
                <td className="px-6 py-4 text-center">{index + 1}</td>
                <td className="px-6 py-4 text-center">{position.type}</td>
                <td className="px-6 py-4 text-center">{position.name}</td>
                <td className="px-6 py-4 text-center">
                  <button className="mx-2 text-red-600 hover:text-red-800">
                    <FaTrash onClick={() => handleDeleteClick(position.id)}/>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="px-6 py-4 text-center">
                Tidak ada data jabatan
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="shawdow-md rounded bg-white p-4">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Jabatan
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus jabatan ini?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Jabatan;
