import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

function CardPengumuman() {
  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    const fetchAnnouncementData = async () => {
      try {
        const response = await axios.get(
          `/api_s/announcements`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        setAnnouncement(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchAnnouncementData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Pengumuman
        </span>
      </h3>
      <div className="mt-4">
        {announcement.map((item) => (
          <div
            key={item.id}
            className="relative mb-4 rounded-lg border border-gray-300 p-4 shadow-md"
          >
            <Link to={`/pengumuman/${item.slug}`}>
              <h2 className="text-xl font-semibold hover:cursor-pointer hover:text-blue-700">
                {item.title}
              </h2>
            </Link>
            <div className="mt-2 mb-2 text-justify text-gray-700">
              {parse(item.body)}
            </div>
            <div className="absolute bottom-0 right-0 rounded-tl-md rounded-br-md bg-[#2561BD] px-1">
              <span className="text-sm text-white">
                {formatDate(item.createdAt)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardPengumuman;
