import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import tiktok from "../../../../assets/img/icon-sosmed/tiktok.png";
import whatsapp from "../../../../assets/img/icon-sosmed/wa.png";
import facebook from "../../../../assets/img/icon-sosmed/fb.png";
import youtube from "../../../../assets/img/icon-sosmed/yt.png";
import instagram from "../../../../assets/img/icon-sosmed/ig.png";
import X from "../../../../assets/img/icon-sosmed/x.png";
import telegram from "../../../../assets/img/icon-sosmed/telegram.png";
import linkedin from "../../../../assets/img/icon-sosmed/linkedIn.png";
import line from "../../../../assets/img/icon-sosmed/line.png";
import messenger from "../../../../assets/img/icon-sosmed/messenger.png";
import EditMedsos from "./EditMedsos";

function TableMedsos() {
  const [medsos, setMedsos] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteMedsosId, setDeleteMedsosId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editMedsosId, setEditMedsosId] = useState(null);

  const socialMediaIcons = [
    { name: "Whatsapp", icon: whatsapp },
    { name: "Facebook", icon: facebook },
    { name: "Instagram", icon: instagram },
    { name: "X (Twitter)", icon: X },
    { name: "Youtube", icon: youtube },
    { name: "Tiktok", icon: tiktok },
    { name: "Telegram", icon: telegram },
    { name: "LinkedIn", icon: linkedin },
    { name: "Line", icon: line },
    { name: "Messenger", icon: messenger },
  ];

  const getIconByTitle = (title) => {
    const matchedIcon = socialMediaIcons.find((icon) => icon.name === title);
    return matchedIcon ? matchedIcon.icon : null;
  };

  const fetchMedsosData = async () => {
    try {
      const response = await axios.get(`/api_s/medsos`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setMedsos(response.data.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchMedsosData();
  }, []);

  const handleEditClick = (id) => {
    setEditMedsosId(id);
    setShowEditModal(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteMedsosId(id);
    setShowDeletePrompt(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_s/medsos/${deleteMedsosId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      toast.success("Berhasil Hapus Media Sosial!");
      const response = await axios.get(`/api_s/medsos`, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setMedsos(response.data.data);
    } catch (error) {
      toast.error(
        "Error Hapus Media Sosial" +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div className="">
      <table className="w-full bg-white">
        <thead className="bg-blue-500 text-sm uppercase leading-normal text-white">
          <tr>
            <th className="px-6 py-3 text-center">No</th>
            <th className="px-6 py-3 text-center">Icon</th>
            <th className="px-6 py-3 text-center">Judul</th>
            <th className="px-6 py-3 text-center">Link</th>
            <th className="px-6 py-3 text-center">Aksi</th>
          </tr>
        </thead>
        <tbody className="text-sm ">
          {medsos.map((item, index) => (
            <tr
              key={item.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              <td className="px-6 py-3 text-center">{index + 1}</td>
              <td className="flex items-center justify-center px-6 py-3 text-center">
                <img
                  src={getIconByTitle(item.title)}
                  alt={item.title}
                  className="h-10 w-10"
                />
              </td>
              <td className="px-6 py-3 text-center">{item.title}</td>
              <td className="px-6 py-3 text-left text-blue-600">{item.link}</td>
              <td className="px-6 py-3 text-center">
                <div className="item-center flex justify-center">
                  <button
                    className="mr-2 w-4 transform text-blue-400 hover:scale-110 hover:text-blue-600"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <FaEdit className="h-4 w-4" />
                  </button>
                  <button
                    className="mr-2 w-4 transform text-red-500 hover:scale-110 hover:text-red-600"
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Media Sosial
            </h2>
            <p className="mb-4">
              Apakah anda yakin akan menghapus media Sosial ini?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Medsos Modal */}
      {showEditModal && (
        <EditMedsos
          id={editMedsosId}
          onClose={() => setShowEditModal(false)}
          medsosData={fetchMedsosData}
        />
      )}
    </div>
  );
}

export default TableMedsos;
