import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCalendarMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import parse from "html-react-parser";

function ListAgenda() {
  const [agenda, setAgenda] = useState([]);

  useEffect(() => {
    const fetchAgendas = async () => {
      try {
        const response = await axios.get("/api_s/agendas", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setAgenda(response.data.data);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchAgendas();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-[20px]">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Agenda
        </span>
      </h3>
      <ol className="relative mt-6 border-s border-gray-600">
        {agenda.length > 0 ? (
          agenda.map((item) => (
            <li key={item.id} className="mb-8 ms-6">
              <span className="absolute -start-3 flex h-7 w-7 items-center justify-center rounded-full bg-blue-200 ring-8 ring-white">
                <FaCalendarMinus className="h-3.5 w-3.5 text-blue-800" />
              </span>
              <Link to={`/agenda/${item.slug}`}>
                <div className="mb-1 flex items-center text-lg font-semibold text-gray-900 hover:text-blue-700">
                  {item.title}
                </div>
              </Link>
              <div className="mb-2 block text-sm font-normal leading-none text-gray-400">
                {formatDate(item.date)}
              </div>
              <p className="mb-4 text-base font-normal text-gray-500">
                {parse(truncateText(item.body, 400))}
              </p>
            </li>
          ))
        ) : (
          <li className="mb-8 ms-6">
            <span className="absolute -start-3 flex h-7 w-7 items-center justify-center rounded-full bg-blue-200 ring-8 ring-white">
              <FaCalendarMinus className="h-3.5 w-3.5 text-blue-800" />
            </span>
            <Link to="/agenda/">
              <div className="mb-1 flex items-center text-lg font-semibold text-gray-900 hover:text-blue-700">
                Agenda Kegiatan 1
              </div>
            </Link>
            <div className="mb-2 block text-sm font-normal leading-none text-gray-400">
              Kamis, 12 September 2024
            </div>
            <p className="mb-4 text-base font-normal text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
              quidem quae, nulla libero eaque, nihil quaerat pariatur magnam
              aperiam quam excepturi similique non praesentium? Ex ducimus
              deleniti reiciendis consequuntur nihil.
            </p>
            {/* <div className="flex justify-end">
            <Link
              to="/agenda/"
              className="inline-flex items-center rounded-lg border border-gray-200 bg-[#2561BD] px-2 py-2 text-sm font-medium text-white hover:bg-blue-500"
            >
              Lihat Selengkapnya
            </Link>
          </div> */}
          </li>
        )}
      </ol>
    </div>
  );
}

export default ListAgenda;
