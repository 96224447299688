import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

if (window.location.pathname.indexOf('/web') != 0){

  window.location.href = "/web";

}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter  basename="/web">
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
