import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import TableKategoriVideo from "./components/TableKategoriVideo";
import AddKategoriVideo from "./components/AddKategoriVideo";

function KategoriVideo() {
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [categoriesvideo, setCategoriesVideo] = useState([]);

    useEffect(() => {
        fetchCategoriesVideo();
    }, []);
    const fetchCategoriesVideo = async () => {
        try {
          const response = await axios.get(
            `/api_s/galerycategories`,
            {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            }
          );
          setCategoriesVideo(response.data.data);
        } catch (error) {
          toast.error("Error fetching categories");
        }
      };

      const handleTambahKategori = () => {
        setShowAddPopup(true);
      };

      const handleClosePopup = () => {
        setShowAddPopup(false);
      };
  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-end">
        <button onClick={handleTambahKategori} className="mr-2 rounded bg-blue-500 px-4 py-2 text-white">
          Tambah Kategori Video
        </button>
      </div>
      <TableKategoriVideo
        categoriesvideo={categoriesvideo}
        fetchCategoriesVideo={fetchCategoriesVideo}
      ></TableKategoriVideo>
      {showAddPopup && (
        <AddKategoriVideo onClose={handleClosePopup} fetchCategoriesVideo={fetchCategoriesVideo}></AddKategoriVideo>
      )}
    </div>
  );
}
export default KategoriVideo;
