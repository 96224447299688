import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddContact = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    link_maps: "",
    coordinates: "",
  });

  const handleCancel = () => {
    navigate("/admin/kontak");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        ...formData,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/contacts`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(response.data.data);
      toast.success("Berhasil Tambah Kontak Sekolah!");
      navigate("/admin/kontak");
    } catch (error) {
      toast.error(
        "Error Tambah Kontak Sekolah: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Phone
        </label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="628784362936"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Email
        </label>
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="smp.muh.bantul@gmail.com"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Link Maps
        </label>
        <input
          type="text"
          name="link_maps"
          value={formData.link_maps}
          onChange={handleChange}
          placeholder="https://maps.app.goo.gl/mffxZ3Sn14mYZu6j9"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
          Coordinates
        </label>
        <input
          type="text"
          name="coordinates"
          value={formData.coordinates}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
        />
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Tambah
        </button>
      </div>
    </form>
  );
};

export default AddContact;
