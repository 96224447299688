import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Tabs from "./Tabs";

const AddUser = () => {
  const navigate = useNavigate();
  //register
  const [formDataRegister, setFormDataRegister] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    phone: "",
    profile_pict: "",
    role: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const handleCancel = () => {
    navigate("/admin/user");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataRegister((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const data = new FormData();
    data.append("file", selectedFile);

    try {
      const response = await axios.post(`/api_s/upload/single`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });

      return response.data.data.Location;
    } catch (error) {
      toast.error(
        "Gagal Upload Gambar" + (error.response?.data?.message || error.message)
      );
      throw error;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const imageURL = await handleFileUpload();
      const postData = {
        ...formDataRegister,
        profile_pict: imageURL,
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_s/users/register`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Registrasi!");
      navigate("/admin/user");
    } catch (error) {
      toast.error(
        "Error Registrasi: " + (error.response?.data?.message || error.message)
      );
    }
  };

  //change password
  const [formDataChangePassword, setFormDataChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordValidate: "",
  });

  const handleChangeUbahPassword = (e) => {
    const { name, value } = e.target;
    setFormDataChangePassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (
      formDataChangePassword.newPassword !==
      formDataChangePassword.newPasswordValidate
    ) {
      toast.error("Password baru dan konfirmasi password tidak cocok.");
      return;
    }
    try {
      const postDataChangePass = {
        ...formDataChangePassword,
      };
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `/api_s/users/changepassword`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postDataChangePass),
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Ubah Password!");
      navigate("/admin/user");
    } catch (error) {
      toast.error(
        "Error Ubah Password: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  //reset password
  const [formDataResetPassword, setFormDataResetPassword] = useState({
    newPassword: "",
  });

  const handleChangeResetPassword = (e) => {
    const { name, value } = e.target;
    setFormDataResetPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const postDataResetPass = {
        ...formDataResetPassword,
      };
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `/api_s/users/resetpassword`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postDataResetPass),
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil reset Password!");
      navigate("/admin/user");
    } catch (error) {
      toast.error(
        "Error Reset Password: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const tabsData = [
    {
      label: "REGISTRASI",
      content: (
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Foto Profil
            </label>
            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Preview"
                className="ml-4 h-auto w-auto"
              />
            )}
            <input
              type="file"
              name="profil_pict"
              onChange={handleFileChange}
              className="w-full rounded-md px-2 py-2 dark:text-white"
            />
            <span className="ml-1 mt-0 block text-xs text-gray-600">
              *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
            </span>
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formDataRegister.name}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mb-4">
              <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
                Username
              </label>
              <input
                type="text"
                name="username"
                value={formDataRegister.username}
                onChange={handleChange}
                className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              />
            </div>
            <div className="mx-auto mb-4">
              <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
                Email
              </label>
              <input
                type="text"
                name="email"
                value={formDataRegister.email}
                onChange={handleChange}
                className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formDataRegister.password}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Phone Number
            </label>
            <input
              type="text"
              name="phone"
              value={formDataRegister.phone}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Role
            </label>
            <select
              name="role"
              value={formDataRegister.role}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            >
              <option value="" disabled>
                Pilih Role
              </option>
              <option value="superadmin">superadmin</option>
              <option value="admin">admin</option>
              <option value="user">user</option>
            </select>
          </div>
          <div className="flex justify-end gap-4">
            <button
              onClick={handleCancel}
              type="button"
              className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Tambah User
            </button>
          </div>
        </form>
      ),
    },
    {
      label: "UBAH PASSWORD",
      content: (
        <form onSubmit={handleChangePassword}>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Password Lama
            </label>
            <input
              type="password"
              name="oldPassword"
              value={formDataChangePassword.oldPassword}
              onChange={handleChangeUbahPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Password Baru
            </label>
            <input
              type="password"
              name="newPassword"
              value={formDataChangePassword.newPassword}
              onChange={handleChangeUbahPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Konfirmasi Password Baru
            </label>
            <input
              type="password"
              name="newPasswordValidate"
              value={formDataChangePassword.newPasswordValidate}
              onChange={handleChangeUbahPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Ubah Password
            </button>
          </div>
        </form>
      ),
    },
    {
      label: "PASSWORD RESET",
      content: (
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label className="text-md block font-bold font-medium text-gray-800 dark:text-white">
              Password Baru
            </label>
            <input
              type="password"
              name="newPassword"
              value={formDataResetPassword.newPassword}
              onChange={handleChangeResetPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Reset Password
            </button>
          </div>
        </form>
      ),
    },
  ];
  return (
    <div className="p-4">
      <Tabs tabs={tabsData} />
    </div>
  );
};
export default AddUser;
