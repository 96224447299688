import React from "react";

const SelectFields = ({
  label,
  name,
  value,
  onChange,
  options,
  update,
  getSelectedName,
}) => {
  return (
    <div className="mb-4">
      <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
        {label}
      </label>
      {update ? (
        <select
          name={name}
          value={value}
          onChange={onChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
        >
          <option value="">Pilih {label}</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <div className="block w-full rounded-md border-2 bg-gray-100 px-3 py-2 shadow-sm outline-none">
          {getSelectedName(value)}
        </div>
      )}
    </div>
  );
};

export default SelectFields;
