import React from "react";
import { useNavigate } from "react-router-dom";
import TableJabatan from "../jabatan/components/TableJabatan";

function Jabatan() {
  const navigate = useNavigate();
 
  return(
    <div className="container mx-auto p-4">
        <div className="mb-4 flex items-center justify-between">
            <TableJabatan></TableJabatan>
        </div>
    </div>
  );
}
export default Jabatan;
