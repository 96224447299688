import React, { useState } from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import Menu from "./components/Menu";
import { tampilan } from "routes.js";
import { MdLogout } from "react-icons/md";
import logo from "assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //logout
  const handleLogout = async () => {
    const isConfirmed = window.confirm("Apakah Anda yakin ingin logout?");
    if (!isConfirmed) return;

    try {
      await axios.delete(`/api_s/users/logout`, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("role");
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      toast.error("Gagal Logout");
    }
  };
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[22px] flex items-center`}>
        <Link to={"/admin"} className="flex items-center">
          <img src={logo} alt="Logo" className="mr-2 mt-5 h-12 w-auto" />
          <div className="ml-1 mt-5 flex flex-col items-center text-navy-700 dark:text-white">
            <span className="font-poppins text-[20px] font-bold uppercase">
              SD Muhammadiyah
            </span>
            <span className="font-poppins text-[20px] font-bold uppercase">
              Karangkajen
            </span>
          </div>
        </Link>
      </div>

      <div class="mb-7 mt-[28px] h-px bg-gray-300 dark:bg-white/30" />

      <ul
        className="mb-auto pt-1"
        style={{
          overflowY: "auto",
          paddingRight: "17px",
          maxHeight: "calc(100vh - 188px)",
          scrollbarWidth: "thin",
        }}
      >
        <Links routes={routes} />
        <Menu />
        <Links routes={tampilan} />
        <div className="py-10">
          <li
            className="flex cursor-pointer items-center py-3 pl-8 text-base font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-white/10"
            onClick={handleLogout}
          >
            <MdLogout className="mr-4 h-6 w-6" />
            Logout
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
